/* FAMILY_VARISANGYAS */
export const GET_ALL_MAIN_VARISANGYAS = "GET_ALL_MAIN_VARISANGYAS"
export const GET_ALL_MAIN_VARISANGYAS_SUCCESS =
  "GET_ALL_MAIN_VARISANGYAS_SUCCESS"
export const GET_ALL_MAIN_VARISANGYAS_FAIL = "GET_ALL_MAIN_VARISANGYAS_FAIL"

/* FAMILY_VARISANGYAS CARDS*/
export const GET_FAMILY_VARISANGYA_CARDS = "GET_FAMILY_VARISANGYA_CARDS"
export const GET_FAMILY_VARISANGYA_CARDS_SUCCESS =
  "GET_FAMILY_VARISANGYA_CARDS_SUCCESS"
export const GET_FAMILY_VARISANGYA_CARDS_FAIL =
  "GET_FAMILY_VARISANGYA_CARDS_FAIL"

/* FAMILY_VARISANGYA  DETAIL*/
export const GET_FAMILY_VARISANGYA_DETAIL = "GET_FAMILY_VARISANGYA_DETAIL"
export const GET_FAMILY_VARISANGYA_DETAIL_SUCCESS =
  "GET_FAMILY_VARISANGYA_DETAIL_SUCCESS"
export const GET_FAMILY_VARISANGYA_DETAIL_FAIL =
  "GET_FAMILY_VARISANGYA_DETAIL_FAIL"

/**
 * add FAMILY_VARISANGYA
 */
export const CREATE_MAIN_VARISANGYA = "CREATE_MAIN_VARISANGYA"
export const CREATE_MAIN_VARISANGYA_SUCCESS = "CREATE_MAIN_VARISANGYA_SUCCESS"
export const CREATE_MAIN_VARISANGYA_FAIL = "CREATE_MAIN_VARISANGYA_FAIL"

/**
 * Edit FAMILY_VARISANGYA
 */
export const UPDATE_MAIN_VARISANGYA = "UPDATE_MAIN_VARISANGYA"
export const UPDATE_MAIN_VARISANGYA_SUCCESS = "UPDATE_MAIN_VARISANGYA_SUCCESS"
export const UPDATE_MAIN_VARISANGYA_FAIL = "UPDATE_MAIN_VARISANGYA_FAIL"

/**
 * Delete FAMILY_VARISANGYA
 */
export const DELETE_MAIN_VARISANGYA = "DELETE_MAIN_VARISANGYA"
export const DELETE_MAIN_VARISANGYA_SUCCESS = "DELETE_MAIN_VARISANGYA_SUCCESS"
export const DELETE_MAIN_VARISANGYA_FAIL = "DELETE_MAIN_VARISANGYA_FAIL"

// AUTO_GENERATE_FAMILY_VARISANGYAS
export const AUTO_GENERATE_FAMILY_VARISANGYAS =
  "AUTO_GENERATE_FAMILY_VARISANGYAS"
export const AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS =
  "AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS"
export const AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL =
  "AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL"

// REFRESH_FAMILY_VARISANGYA
export const REFRESH_FAMILY_VARISANGYA = "REFRESH_FAMILY_VARISANGYA"
export const REFRESH_FAMILY_VARISANGYA_SUCCESS =
  "REFRESH_FAMILY_VARISANGYA_SUCCESS"
export const REFRESH_FAMILY_VARISANGYA_FAIL = "REFRESH_FAMILY_VARISANGYA_FAIL"

// UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER"
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS"
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL"
