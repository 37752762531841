import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"
import moment from "moment"

import { createFamilyVarisangya, createVarisangyaPeriod } from "store/actions"
import { handleFinancialYear, months } from "pages/admin/Mahalls/financialYear"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UiModalCreateVarisangyaPeriod = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [financialYear, setFinancialYear] = useState(0)

  const [isSingleEntry, setIsSingleEntry] = useState(false)

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  // const today = moment(new Date()).format('yyyy-MM-DD')

  const { fromDate } = handleFinancialYear(financialYear)

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createVarisangyaData = {
      title: values.title,
      // date: values.date
      date: moment(fromDate).format("yyyy-MM-DD"),
      is_single_entry: isSingleEntry,
      varisankya: params?.id,
    }
    // console.log(createVarisangyaData);
    dispatch(createVarisangyaPeriod(createVarisangyaData))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Create Varisangya</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="title"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="4">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    type="select"
                    name="date"
                    value="1"
                    validate={{ required: { value: true } }}
                    onChange={e => {
                      setFinancialYear(e.target.value)
                    }}
                  >
                    {/* <option>Select month...</option> */}
                    {months?.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item?.label}
                      </option>
                    ))}
                  </AvField>
                  {/* <AvField
                    name="date"
                    type="date"
                    value={today}
                    validate={{
                      required: { value: true },
                    }}
                  /> */}
                </Col>
                <Col sm="12" md="5">
                  <Label for="basicpill-email-input4">
                    {language === "mal"
                      ? "സിംഗിൾ എൻട്രി ആണോ?"
                      : "Is Single Entry?"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isSingleEntry ? true : false}
                      onChange={() => setIsSingleEntry(!isSingleEntry)}
                    />
                  </div>
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalCreateVarisangyaPeriod
UiModalCreateVarisangyaPeriod.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
}
