import {
  GET_FILTERED_MEMBERS,
  GET_FAMILY_VARISANGYA_WALLETS_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLETS_FAIL,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_VIEW,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL,
  DELETE_FAMILY_VARISANGYA_WALLET,
  DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS,
  DELETE_FAMILY_VARISANGYA_WALLET_FAIL,
  GET_VARISANGYA_WALLETS_SUCCESS,
  GET_VARISANGYA_WALLETS_FAIL,
  GET_FILTERED_MEMBERS_SUCCESS,
  GET_FILTERED_MEMBERS_FAIL,
  UPDATE_FILTERED_WALLETS,
  UPDATE_FILTERED_WALLETS_SUCCESS,
  UPDATE_FILTERED_WALLETS_FAIL,
  GET_VARISANGYA_WALLET_DETAIL,
  GET_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_VARISANGYA_WALLET_DETAIL_FAIL,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL,
  GET_ALL_FAMILY_VARISANGYA_WALLET,
  GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS,
  GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL,
  DELETE_VARISANGYA_WALLET_FAIL,
  DELETE_VARISANGYA_WALLET_SUCCESS,
  DELETE_VARISANGYA_WALLET,
  GET_VARISANGYA_TRANSACTION_DETAIL,
  GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS,
  GET_VARISANGYA_TRANSACTION_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  filteredMembers: [],
  varisangyaWalletDetail: {},
  allFamilyVarisangyaWallet: {},
  allFamilyMemberVarisangyaWallet: {},
  error: {},
  loading: false,
  tableLoading: false,
  pageLoading: false,
  deleteLoading: false,
  createdFamilyVarisangyaWallet: {},
  createdFamilyMemberVarisangyaWallet: {},
  transactionDetails: {},
}

const VarisangyaWallets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        familyVarisangyaWalletDetail: [],
      }
    case GET_FILTERED_MEMBERS:

    case UPDATE_FILTERED_WALLETS:
    case CREATE_FAMILY_VARISANGYA_WALLET_NEW:
    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_FAMILY_VARISANGYA_WALLET:
      return {
        ...state,
        tableLoading: true,
      }
    case GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET:
      return {
        ...state,
        tableLoading: true,
      }
    case GET_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        tableLoading: true,
      }

    case GET_VARISANGYA_TRANSACTION_DETAIL:
      return {
        ...state,
        loading: true,
      }

    case GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionDetails: action.payload,
      }

    case GET_VARISANGYA_TRANSACTION_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_FILTERED_MEMBERS_SUCCESS:
      return {
        ...state,
        filteredMembers: action.payload,
        loading: false,
      }
    case GET_FILTERED_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS:
      return {
        ...state,
        createdFamilyVarisangyaWallet: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS:
      return {
        ...state,
        createdFamilyMemberVarisangyaWallet: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case CREATE_FAMILY_VARISANGYA_WALLET_SUCCESS:
    //   return {
    //     ...state,
    //     familyVarisangyaWallets: action.payload,
    //     familyVarisangyaWalletDetail: {
    //       ...state.familyVarisangyaWalletDetail,
    //       results: [
    //         ...state.familyVarisangyaWalletDetail.results,
    //         action.payload,
    //       ],
    //     },
    //     createdFamilyVarisangyaWallet: action.payload,
    //     loading: false,
    //   }

    // case CREATE_FAMILY_VARISANGYA_WALLET_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loading: false,
    //   }

    case GET_VARISANGYA_WALLET_DETAIL_SUCCESS:
      return {
        ...state,
        varisangyaWalletDetail: action.payload,
        tableLoading: false,
      }

    case UPDATE_FILTERED_WALLETS_SUCCESS:
      return {
        ...state,
        filteredMembers: action.payload,
        loading: false,
      }
    case UPDATE_FILTERED_WALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_VARISANGYA_WALLET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        allFamilyVarisangyaWallet: action.payload,
        tableLoading: false,
      }
    case GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        allFamilyMemberVarisangyaWallet: action.payload,
        tableLoading: false,
      }
    case GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case DELETE_VARISANGYA_WALLET:
      return {
        ...state,
        deleteLoading: true,
      }

    case DELETE_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      }
    case DELETE_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default VarisangyaWallets
