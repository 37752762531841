import {
  GET_FILTERED_MEMBERS,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_VIEW,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL,
  DELETE_FAMILY_VARISANGYA_WALLET,
  DELETE_FAMILY_VARISANGYA_WALLET_FAIL,
  DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS,
  GET_FILTERED_MEMBERS_SUCCESS,
  GET_FILTERED_MEMBERS_FAIL,
  UPDATE_FILTERED_WALLETS,
  UPDATE_FILTERED_WALLETS_SUCCESS,
  UPDATE_FILTERED_WALLETS_FAIL,
  GET_VARISANGYA_WALLET_DETAIL,
  GET_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_VARISANGYA_WALLET_DETAIL_FAIL,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS,
  CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL,
  GET_ALL_FAMILY_VARISANGYA_WALLET,
  GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS,
  GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
  GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL,
  DELETE_VARISANGYA_WALLET,
  DELETE_VARISANGYA_WALLET_SUCCESS,
  DELETE_VARISANGYA_WALLET_FAIL,
  GET_VARISANGYA_TRANSACTION_DETAIL,
  GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS,
  GET_VARISANGYA_TRANSACTION_DETAIL_FAIL,
} from "./actionTypes"

export const getFilteredMembers = (
  varisangyaId,
  searchText,
  pageSize,
  page,
  filterData,
  type
) => (
  console.log(filterData),
  {
    type: GET_FILTERED_MEMBERS,
    payload: { varisangyaId, searchText, pageSize, page, filterData, type },
  }
)
export const getFilteredMembersSuccess = filteredMembers => ({
  type: GET_FILTERED_MEMBERS_SUCCESS,
  payload: filteredMembers,
})
export const getFilteredMembersFail = error => ({
  type: GET_FILTERED_MEMBERS_FAIL,
  payload: error,
})

export const updateFilteredWallets = (
  filteredVarisangyaWallet,
  history,
  type,
  varisangyaId
) => ({
  type: UPDATE_FILTERED_WALLETS,
  payload: { filteredVarisangyaWallet, history, type, varisangyaId },
})

export const updateFilteredWalletsSuccess = familyVarisangyaWallet => ({
  type: UPDATE_FILTERED_WALLETS_SUCCESS,
  payload: familyVarisangyaWallet,
})

export const updateFilteredWalletsFail = error => ({
  type: UPDATE_FILTERED_WALLETS_FAIL,
  payload: error,
})

export const getVarisangyaWalletDetail = (walletId, type) => ({
  type: GET_VARISANGYA_WALLET_DETAIL,
  payload: { walletId, type },
})

export const getVarisangyaWalletDetailSuccess = varisangyaWalletDetail => ({
  type: GET_VARISANGYA_WALLET_DETAIL_SUCCESS,
  payload: varisangyaWalletDetail,
})

export const getVarisangyaWalletDetailFail = error => ({
  type: GET_VARISANGYA_WALLET_DETAIL_FAIL,
  payload: error,
})

export const clearFamilyVarisangyaWalletDetail = () => ({
  type: CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL,
})

export const getAllFamilyVarisangyaWallet = (
  page,
  search,
  limit,
  walletId,
  varinsangyaId,
  familyId
) => ({
  type: GET_ALL_FAMILY_VARISANGYA_WALLET,
  payload: { page, search, limit, walletId, varinsangyaId, familyId },
})

export const getAllFamilyVarisangyaWalletSuccess =
  familyVarisangyaWalletView => ({
    type: GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS,
    payload: familyVarisangyaWalletView,
  })

export const getAllFamilyVarisangyaWalletFail = error => ({
  type: GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getAllFamilyMemberVarisangyaWallet = (
  page,
  search,
  limit,
  walletId,
  varisangyaId,
  familyMemberId
) => ({
  type: GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET,
  payload: { page, search, limit, walletId, varisangyaId, familyMemberId },
})

export const getAllFamilyMemberVarisangyaWalletSuccess =
  familyVarisangyaWalletView => ({
    type: GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
    payload: familyVarisangyaWalletView,
  })

export const getAllFamilyMemberVarisangyaWalletFail = error => ({
  type: GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const createFamilyVarisangyaWalletNew = (
  familyVarisangyaWallet,
  history,
  page,
  handleReset
) => ({
  type: CREATE_FAMILY_VARISANGYA_WALLET_NEW,
  payload: { familyVarisangyaWallet, history, page, handleReset },
})

export const createFamilyVarisangyaWalletNewSuccess =
  familyVarisangyaWallet => ({
    type: CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS,
    payload: familyVarisangyaWallet,
  })

export const createFamilyVarisangyaWalletNewFail = error => ({
  type: CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL,
  payload: error,
})

export const createFamilyMemberVarisangyaWalletNew = (
  familyMemberVarisangyaWallet,
  history,
  formRef
) => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW,
  payload: { familyMemberVarisangyaWallet, history, formRef },
})

export const createFamilyMemberVarisangyaWalletNewSuccess =
  familyVarisangyaWallet => ({
    type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS,
    payload: familyVarisangyaWallet,
  })

export const createFamilyMemberVarisangyaWalletNewFail = error => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL,
  payload: error,
})

export const deleteVarisangyaWallet = (
  transactionId,
  type,
  varisnakyaId,
  memberId
) => ({
  type: DELETE_VARISANGYA_WALLET,
  payload: { transactionId, type, varisnakyaId, memberId },
})
export const deleteVarisangyaWalletSuccess = res => ({
  type: DELETE_VARISANGYA_WALLET_SUCCESS,
  payload: res,
})
export const deleteVarisangyaWalletFail = error => ({
  type: DELETE_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getVarisangyaTransactionDetail = (transactionId, type) => ({
  type: GET_VARISANGYA_TRANSACTION_DETAIL,
  payload: { transactionId, type },
})

export const getVarisangyaTransactionDetailsSuccess = transactionDetails => ({
  type: GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS,
  payload: transactionDetails,
})

export const getVarisangyaTransactionDetailFail = error => ({
  type: GET_VARISANGYA_TRANSACTION_DETAIL_FAIL,
  payload: error,
})
