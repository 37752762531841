import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import FamilyVarisangyaTransactionPrints from "./family/print"
import MemberVarisangyaTransactionPrints from "./member/print"

function VarisangyaWalletPrint() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  const params = useParams()

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="" breadcrumbItem="Receipt" />
        <Container fluid>
          <div className="container-fluid">
            {params?.type === "family" ? (
              <FamilyVarisangyaTransactionPrints />
            ) : (
              <MemberVarisangyaTransactionPrints />
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default VarisangyaWalletPrint
