import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"
import Select from "react-select"

import {
  deleteFamilyVarisangya,
  getLedgerDropdown,
  updateFamilyVarisangya,
} from "store/actions"
import { updateMainVarisangya } from "store/mainVarisangya/actions"

const UiModalUpdateVarisangya = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const varisangya = data

  const [isSingleEntry, setIsSingleEntry] = useState(false)
  const [isAutoPay, setIsAutoPay] = useState(false)

  const [ledger, setLedger] = useState("Select ledger...")
  const [ledgerId, setLedgerId] = useState()
  const [searchText, setSearchText] = useState("")

  const { loading, language, searchLedgers } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    searchLedgers: state.Ledgers.searchLedgers,
  }))

  const [isActiveSwitch, setIsActiveSwitch] = useState()

  useEffect(() => {
    setIsActiveSwitch(varisangya?.is_active)
    setLedger(varisangya?.ledger_name)
    setLedgerId(varisangya?.ledger)
    setIsAutoPay(varisangya?.autopay)
    setIsSingleEntry(varisangya?.is_single_entry)
  }, [varisangya])

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateVarisangyaData = {
      ...values,
      is_single_entry: isSingleEntry,
      type: varisangya?.type,
      ledger: ledgerId,
      // autopay: isAutoPay,
    }
    dispatch(updateMainVarisangya(updateVarisangyaData, varisangya?.id))
  }

  const handleDelete = id => {
    dispatch(deleteFamilyVarisangya(id))
  }

  useEffect(() => {
    dispatch(getLedgerDropdown(searchText))
  }, [dispatch, searchText])

  const optionGroup = [
    {
      options: searchLedgers?.map((result, index) => ({
        key: index,
        label: `${result?.institute_ledger_name} (${
          language === "mal"
            ? result?.institute_malayalam_name
              ? result?.institute_malayalam_name
              : result?.institute_english_name
            : result?.institute_english_name
        }) (${result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setLedgerId(event.value)
    setLedger(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Varisangya</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="title"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    value={varisangya?.title}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col md="12" className="db__column">
                  <Label>
                    {language === "mal" ? "ലെഡ്ജർ" : "Ledger"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={ledger}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={e => handlerFinalValue(e)}
                    type="text"
                    title="Ledger"
                    required="required"
                  />
                  {/* <AvField
                                        name="institute_ledger"
                                        type="text"
                                        label="Ledger"
                                        onChange={e => {
                                          const value = e.target.value
                                          setSplitLedgers(
                                            splitLedger =>
                                              splitLedger.map(x =>
                                                x === item
                                                  ? { ...x, value }
                                                  : x
                                              )
                                          )
                                        }}
                                      /> */}
                </Col>
              </div>
              <div className="row mb-4">
                {/* <Col sm="12" md="4">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    type="select"
                    name="date"
                    value="1"
                    validate={{ required: { value: true } }}
                    onChange={e => {
                      setFinancialYear(e.target.value)
                    }}
                  >
                    {months?.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item?.label}
                      </option>
                    ))}
                  </AvField>
      
                </Col> */}

                <Col sm={4} md="4">
                  <Label>
                    {language === "mal" ? "കാലഘട്ടം" : "Period"}
                    {/* <span className="text-danger">&#42;</span> */}
                  </Label>
                  <AvField
                    name="period"
                    type="number"
                    validate={{
                      required: { value: false },
                    }}
                    value={varisangya?.period}
                  />
                </Col>
                <Col sm="4" md="4">
                  <Label for="basicpill-email-input4">
                    {language === "mal"
                      ? "സിംഗിൾ എൻട്രി ആണോ?"
                      : "Is Single Entry?"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isSingleEntry ? true : false}
                      onChange={() => setIsSingleEntry(!isSingleEntry)}
                    />
                  </div>
                </Col>
                {/* <Col sm="4" md="4">
                  <Label for="basicpill-email-input4">
                    {language === "mal" ? "ഓട്ടോപേ" : "AutoPay"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isAutoPay ? true : false}
                      onChange={() => setIsAutoPay(!isAutoPay)}
                    />
                  </div>
                </Col> */}
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalUpdateVarisangya
UiModalUpdateVarisangya.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
}
