import React from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { MetaTags } from "react-meta-tags"
import FamilyMemberWalletDetail from "./MemberIndex"
import FamilyWalletDetail from "../family/FamilyIndex"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"

function FamilySingleDetails() {
  const params = useParams()
  const {
    // loading,
    language,
    // familyVarisangyaItems
  } = useSelector(state => ({
    language: state.Layout.language,
    // familyVarisangyaItems: state.FamilyVarisangyas.familyVarisangyaItems
  }))
  return (
    <div>
      <MetaTags>
        <title>Wallet | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വരിസംഖ്യ" : "Varisangya"}
          breadcrumbItem={
            language === "mal" ? "വാലറ്റ് വിശദാംശങ്ങൾ" : "Wallet Details"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            {params?.type === "family" ? (
              <FamilyWalletDetail />
            ) : (
              <FamilyMemberWalletDetail />
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default FamilySingleDetails
