import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "pages/common/Authentication/Login"
import Logout from "pages/common/Authentication/Logout"
import UiModal from "pages/admin/MahallUsers/UiModal"
import Pages500 from "pages/common/404/pages-500"
import Pages404 from "pages/common/404/pages-404"
//pages
// Admin
import ForgetPwd from "pages/common/Authentication/ForgetPwd"
import Mahall from "pages/admin/Mahalls"
import MahallDetails from "pages/admin/Mahalls/SingleView"
import UpdateMahall from "pages/admin/Mahalls/Crud/Update"
import CreateMahall from "pages/admin/Mahalls/Crud/Create"
import State from "pages/admin/States"
import UpdateState from "pages/admin/States/Crud/Update"
import CreateState from "pages/admin/States/Crud/Create"
import District from "pages/admin/District"
import UpdateDistrict from "pages/admin/District/Crud/Update"
import CreateDistrict from "pages/admin/District/Crud/Create"
import Village from "pages/admin/Villages"
import VillageDetails from "pages/admin/Villages/SingleView"
import UpdateVillage from "pages/admin/Villages/Crud/Update"
import CreateVillage from "pages/admin/Villages/Crud/Create"
import UpdatePhoneFamilyMember from "pages/mahallUser/FamilyMembers/Crud/UpdatePhoneFamilyMember"
import MahallUser from "pages/admin/MahallUsers"
import MahallUserDetails from "pages/admin/MahallUsers/SingleView"
import UpdateMahallUser from "pages/admin/MahallUsers/Crud/Update"
import CreateMahallUser from "pages/admin/MahallUsers/Crud/Create"
import UpdatePhone from "pages/admin/MahallUsers/Crud/UpdatePhone"
import Settings from "pages/admin/Settings"
import Lsg from "pages/admin/Lsg"
import CreateLsg from "pages/admin/Lsg/Crud/Create"
import UpdateLsg from "pages/admin/Lsg/Crud/Update"
import Enquiries from "pages/admin/Enquiries"
import EnquiryDetails from "pages/admin/Enquiries/SingleView"
import CreateEnquiry from "pages/admin/Enquiries/Crud/Create"
import UpdateEnquiry from "pages/admin/Enquiries/Crud/Update"
import LandingPage from "pages/common/LandingPage"
//mahallUser
import MahallUserDashboard from "pages/mahallUser/Dashboard/index"
import MahallFamily from "pages/mahallUser/Families"
import CreateMahallFamily from "pages/mahallUser/Families/Crud/Create"
import UpdateMahallFamily from "pages/mahallUser/Families/Crud/Update"
import MahallFamilyDetails from "pages/mahallUser/Families/SingleView"
import MahallFamilyMember from "pages/mahallUser/FamilyMembers"
import MahallFamilyMemberDetails from "pages/mahallUser/FamilyMembers/SingleView"
import CreateMahallFamilyMember from "pages/mahallUser/FamilyMembers/Crud/Create"
import UpdateMahallFamilyMember from "pages/mahallUser/FamilyMembers/Crud/Update"
import MahallMahallUser from "pages/mahallUser/MahallUsers"
import MahallMahallUserDetails from "pages/mahallUser/MahallUsers/SingleView"
import CreateMahallMahallUser from "pages/mahallUser/MahallUsers/Crud/Create"
import UpdateMahallMahallUser from "pages/mahallUser/MahallUsers/Crud/Update"
import UpdateMahallMahallUserPhone from "pages/mahallUser/MahallUsers/Crud/UpdatePhone"
import AdminDashboard from "pages/admin/Dashboard"
import PagesPremium from "pages/common/404/pages-premium"
import AboutUs from "pages/common/LandingPage/AboutUs/about"
import Role from "pages/common/Authentication/RolePage"
import Admins from "pages/admin/Admins/index"
import Groups from "pages/admin/Groups"
import CreateGroup from "pages/admin/Groups/Crud/Create"
import UpdateGroup from "pages/admin/Groups/Crud/Update"
import GroupAdmins from "pages/admin/GroupAdmins"
import CreateGroupAdmin from "pages/admin/GroupAdmins/Crud/Create"
import UpdateGroupAdmin from "pages/admin/GroupAdmins/Crud/Update"
import SurveyUsers from "pages/mahallUser/SurveyUser"
import CreateSurveyUser from "pages/mahallUser/SurveyUser/Crud/Create"
import UpdateSurveyUser from "pages/mahallUser/SurveyUser/Crud/Update"
import SurveyUserDashboard from "pages/surveyUser/Dashboard/SurveyUserDashboard"
import SurveyUserFamily from "pages/surveyUser/Families"
import SurveyUserFamilyDetails from "pages/surveyUser/Families/SingleView"
import CreateSurveyUserFamily from "pages/surveyUser/Families/Crud/Create"
import UpdateSurveyUserFamily from "pages/surveyUser/Families/Crud/Update"
import SurveyUserFamilyMember from "pages/surveyUser/FamilyMembers"
import SurveyUserFamilyMemberDetails from "pages/surveyUser/FamilyMembers/SingleView"
import CreateSurveyUserFamilyMember from "pages/surveyUser/FamilyMembers/Crud/Create"
import UpdateSurveyUserFamilyMember from "pages/surveyUser/FamilyMembers/Crud/Update"
import CreateMahallInstitute from "pages/mahallUser/Institutes/Crud/Create"
import UpdateMahallInstitute from "pages/mahallUser/Institutes/Crud/Update"
import InstituteMahallDetails from "pages/mahallUser/Institutes/SingleView"
import MahallInstitute from "pages/mahallUser/Institutes"
import GroupAdminMahall from "pages/groupAdmin/Mahalls"
import GroupAdminMahallDetails from "pages/groupAdmin/Mahalls/SingleView"
import CreateGroupAdminMahall from "pages/groupAdmin/Mahalls/Crud/Create"
import UpdateGroupAdminMahall from "pages/groupAdmin/Mahalls/Crud/Update"
import GroupAdminDashboard from "pages/groupAdmin/Dashboard/GroupAdminDashboard"
import BasePage from "pages/common/BasePage"
import TermsAndCondition from "pages/mahallUser/Dashboard/MahallUserDashboard/TermsAndCondition"
import MahallInstituteUser from "pages/mahallUser/InstituteUsers"
import InstituteUserMahallDetails from "pages/mahallUser/InstituteUsers/SingleView"
import CreateMahallInstituteUser from "pages/mahallUser/InstituteUsers/Crud/Create"
import UpdateMahallInstituteUser from "pages/mahallUser/InstituteUsers/Crud/Update"
import MahallLedger from "pages/mahallUser/Ledgers"
import LedgerMahallDetails from "pages/mahallUser/Ledgers/SingleView"
import MahallNikahRegistrations from "pages/mahallUser/NikahRegistrations"
import MahallNikahRegistrationDetails from "pages/mahallUser/NikahRegistrations/SingleView"
import CreateMahallNikahRegistration from "pages/mahallUser/NikahRegistrations/Crud/Create"
import UpdateMahallNikahRegistration from "pages/mahallUser/NikahRegistrations/Crud/Update"
import MahallCommittee from "pages/mahallUser/Committees"
import CommitteeMahallDetails from "pages/mahallUser/Committees/SingleView"
import MahallMasterDetails from "pages/mahallUser/MahallMasters/SingleView"
import MasterAccount from "pages/mahallUser/MasterAccounts"
import InstituteUserDashboard from "pages/instituteUser/Dashboard/InstituteUserDashboard"
import InstituteLedgerDetails from "pages/instituteUser/Ledgers/SingleView"
import InstituteCommitteeDetails from "pages/instituteUser/Committees/SingleView"
import InstituteCommitteeMeetingDetails from "pages/instituteUser/CommitteeMeetings/SingleView"
import CreateInstituteCommitteeMeeting from "pages/instituteUser/CommitteeMeetings/Crud/Create"
import UpdateInstituteCommitteeMeeting from "pages/instituteUser/CommitteeMeetings/Crud/Update"
import MahallDeathRegistrations from "pages/mahallUser/DeathRegistrations"
import MahallDeathRegistrationDetails from "pages/mahallUser/DeathRegistrations/SingleView"
import CreateMahallDeathRegistration from "pages/mahallUser/DeathRegistrations/Crud/Create"
import UpdateMahallDeathRegistration from "pages/mahallUser/DeathRegistrations/Crud/Update"
import MahallCommitteeMeetingDetails from "pages/mahallUser/CommitteeMeetings/SingleView"
import CommitteeMeetings from "pages/mahallUser/CommitteeMeetings"
import CreateMahallCommitteeMeeting from "pages/mahallUser/CommitteeMeetings/Crud/Create"
import UpdateMahallCommitteeMeeting from "pages/mahallUser/CommitteeMeetings/Crud/Update"
import MahallZakats from "pages/mahallUser/Zakat"
import MahallZakatDetails from "pages/mahallUser/Zakat/SingleView/index"
import CreateMahallZakat from "pages/mahallUser/Zakat/Crud/Create"
import UpdateMahallZakat from "pages/mahallUser/Zakat/Crud/Update"
import UnapprovedFamily from "pages/mahallUser/UnapprovedFamilies"
import UnapprovedFamilyDetails from "pages/mahallUser/UnapprovedFamilies/SingleView"
import UpdateUnapprovedFamily from "pages/mahallUser/UnapprovedFamilies/Crud/Update"
import UnapprovedFamilyMemberDetails from "pages/mahallUser/UnapprovedFamilyMembers/SingleView"
import UpdateUnapprovedFamilyMember from "pages/mahallUser/UnapprovedFamilyMembers/Crud/Update"
import Banner from "pages/mahallUser/Banners"
import BannerDetails from "pages/mahallUser/Banners/SingleView"
import CreateBanner from "pages/mahallUser/Banners/Crud/Create"
import UpdateBanner from "pages/mahallUser/Banners/Crud/Update"
import Collections from "pages/mahallUser/Collections"
import CollectionDetails from "pages/mahallUser/Collections/SingleView"
import MahallNotifications from "pages/groupAdmin/Notifications"
import MahallNotificationDetails from "pages/groupAdmin/Notifications/SingleView"
import CreateMahallNotification from "pages/groupAdmin/Notifications/Crud/Create"
import UpdateMahallNotification from "pages/groupAdmin/Notifications/Crud/Update"
import FilteredMemberNotifications from "pages/mahallUser/FilteredMembersNotifications"
import FilteredMemberNotificationDetails from "pages/mahallUser/FilteredMembersNotifications/SingleView"
import CreateFilteredMemberNotification from "pages/mahallUser/FilteredMembersNotifications/Crud/Create"
import UpdateFilteredMemberNotification from "pages/mahallUser/FilteredMembersNotifications/Crud/Update"
import IndividualNotifications from "pages/mahallUser/IndividualNotifications"
import IndividualNotificationDetails from "pages/mahallUser/IndividualNotifications/SingleView"
import CreateIndividualNotification from "pages/mahallUser/IndividualNotifications/Crud/Create"
import UpdateIndividualNotification from "pages/mahallUser/IndividualNotifications/Crud/Update"
import LedgerItem from "pages/mahallUser/LedgerItems"
import CreateNewFilteredMemberNotification from "pages/mahallUser/FilteredMembersNotifications/Crud new/Create"
import UpdateNewFilteredMemberNotification from "pages/mahallUser/FilteredMembersNotifications/Crud new/Update"
import Feeds from "pages/mahallUser/Feeds"
import FeedDetails from "pages/mahallUser/Feeds/SingleView"
import CreateFeed from "pages/mahallUser/Feeds/Crud/Create"
import UpdateFeed from "pages/mahallUser/Feeds/Crud/Update"
import AreaReports from "pages/mahallUser/Reports/areas"
import BloodGroups from "pages/mahallUser/Reports/bloodBank"
import Country from "pages/admin/Countries"
import CreateCountry from "pages/admin/Countries/Crud/Create"
import UpdateCountry from "pages/admin/Countries/Crud/Update"
import Orphans from "pages/mahallUser/Reports/orphans"
import NocCertificates from "pages/mahallUser/NocCertificates"
import CreateNocCertificate from "pages/mahallUser/NocCertificates/Crud/Create"
import CreateNikahRegistrationNoc from "pages/mahallUser/NocCertificates/Crud/CreateNikahRegistrationNoc"
import UpdateNocCertificate from "pages/mahallUser/NocCertificates/Crud/Update"
import NocCertificate from "pages/mahallUser/NocCertificates/Crud/nocCertificate"
import PublicNocCertificate from "pages/common/Certificates/PublicNocCertificate"
import NikahCertificate from "pages/mahallUser/NikahRegistrations/SingleView/nikahCertificate"
import VarisangyaSplits from "pages/mahallUser/VarisangyaSplitLedgers"
import CreateVarisangyaSplit from "pages/mahallUser/VarisangyaSplitLedgers/Crud/Create"
import UpdateVarisangyaSplit from "pages/mahallUser/VarisangyaSplitLedgers/Crud/Update"
import Receipt from "pages/mahallUser/Receipt/receipt"
import FamilyVarisangyas from "pages/mahallUser/FamilyVarisangyas"
import FamilyVarisangyaDetails from "pages/mahallUser/FamilyVarisangyas/SingleView"
import FamilyWalletDetail from "pages/mahallUser/FamilyWalletDetails/SingleView"
import FamilyMemberVarisangyas from "pages/mahallUser/FamilyMemberVarisangyas"
import FamilyMemberVarisangyaDetails from "pages/mahallUser/FamilyMemberVarisangyas/SingleView"
import FamilyMemberWalletDetail from "pages/mahallUser/FamilyMemberWalletDetails/SingleView"
import MemberVarisangyaTransactionPrint from "pages/mahallUser/FamilyMemberVarisangyaTransactions/print"
import ActivityLog from "pages/mahallUser/ActivityLogs"
import InstituteCategories from "pages/mahallUser/InstituteCategories"
import NikahNocCertificates from "pages/mahallUser/NikahNocList"
import CategoryLedgerItems from "pages/mahallUser/LedgerItemsCategoryBased"
import UserFamilyVarisangyas from "pages/varisangyaUser/FamilyVarisangyas"
import UserFamilyVarisangyaDetails from "pages/varisangyaUser/FamilyVarisangyas/SingleView"
import UserCreateFamilyVarisangya from "pages/varisangyaUser/FamilyVarisangyas/Crud/Create"
import UserUpdateFamilyVarisangya from "pages/varisangyaUser/FamilyVarisangyas/Crud/Update"
import AllUserFamilyWalletDetail from "pages/varisangyaUser/FamilyWalletDetails"
import UserFamilyWalletDetail from "pages/varisangyaUser/FamilyWalletDetails/SingleView"
import UserFamilyVarisangyaWallets from "pages/varisangyaUser/FamilyVarisangyaWallet"
import UserCreateFamilyVarisangyaWallet from "pages/varisangyaUser/FamilyVarisangyaWallet/Crud/Create"
import UserFamilyMemberVarisangyas from "pages/varisangyaUser/FamilyMemberVarisangyas"
import UserFamilyMemberVarisangyaDetails from "pages/varisangyaUser/FamilyMemberVarisangyas/SingleView"
import AllUserFamilyMemberWalletDetail from "pages/varisangyaUser/FamilyMemberWalletDetails"
import UserFamilyMemberWalletDetail from "pages/varisangyaUser/FamilyMemberWalletDetails/SingleView"
import UserFamilyMemberVarisangyaWallets from "pages/varisangyaUser/FamilyMemberVarisangyaWallet"
import UserCreateFamilyMemberVarisangyaWallet from "pages/varisangyaUser/FamilyMemberVarisangyaWallet/Crud/Create"
import VarisangyaUserDashboard from "pages/varisangyaUser/Dashboard/VarisangyaUserDashboard"
import ActivityLogsAdmin from "pages/admin/ActivityLogs"
import ActivityLogDetails from "pages/mahallUser/ActivityLogs/SingleView"
import ActivityLogDetailsAdmin from "pages/admin/ActivityLogs/SingleView"
import MahallMadrasas from "pages/mahallUser/madrasas"
import MahallMadrasaDetails from "pages/mahallUser/madrasas/SingleView"
import CreateMahallMadrasa from "pages/mahallUser/madrasas/Crud/Create"
import UpdateMahallMadrasa from "pages/mahallUser/madrasas/Crud/Update"
import ClassDetails from "pages/mahallUser/Classes/SingleView"
import DivisionDetails from "pages/mahallUser/Divisions/SingleView"
import CreateStudent from "pages/mahallUser/Students/Crud/Create"
import UpdateStudent from "pages/mahallUser/Students/Crud/Update"
import StudentAttendanceDetails from "pages/mahallUser/StudentAttendances/singleView"
import StudentVarisangyas from "pages/mahallUser/StudentVarisangyas"
import StudentVarisangyaDetails from "pages/mahallUser/StudentVarisangyas/SingleView"
import StudentWalletDetail from "pages/mahallUser/StudentWalletDetails/SingleView"
import StudentVarisangyaTransactionPrint from "pages/mahallUser/StudentVarisangyaTransactions/print"
import StudentDetails from "pages/mahallUser/Students/SingleView"
import StudentVarisangyaTransactions from "pages/mahallUser/StudentVarisangyaTransactions"
import CreateStudentVarisangyaTransaction from "pages/mahallUser/StudentVarisangyaTransactions/Crud/Create"
import AllStudentWalletLists from "pages/mahallUser/StudentWalletDetails"
import AllFamilyWalletLists from "pages/mahallUser/FamilyWalletDetails"
import CreateFamilyVarisangyaTransaction from "pages/mahallUser/FamilyVarisangyaTransactions/Crud/Create"
import AllFamilyVarisangyaTransactions from "pages/mahallUser/FamilyVarisangyaTransactions"
import FamilyVarisangyaTransactionPrint from "pages/mahallUser/FamilyVarisangyaTransactions/print"
import AllFamilyMemberWalletLists from "pages/mahallUser/FamilyMemberWalletDetails"
import AllFamilyMemberVarisangyaTransactions from "pages/mahallUser/FamilyMemberVarisangyaTransactions"
import CreateFamilyMemberVarisangyaTransaction from "pages/mahallUser/FamilyMemberVarisangyaTransactions/Crud/Create"
import InstituteClassDetails from "pages/instituteUser/Classes/SingleView"
import InstituteDivisionDetails from "pages/instituteUser/Divisions/SingleView"
import InstituteCreateStudent from "pages/instituteUser/Students/Crud/Create"
import InstituteUpdateStudent from "pages/instituteUser/Students/Crud/Update"
import InstituteStudentDetails from "pages/instituteUser/Students/SingleView"
import InstituteStudentAttendanceDetails from "pages/instituteUser/StudentAttendances/singleView"
import InstituteStudentVarisangyas from "pages/instituteUser/StudentVarisangyas"
import InstituteStudentVarisangyaDetails from "pages/instituteUser/StudentVarisangyas/SingleView"
import AllInstituteStudentWalletLists from "pages/instituteUser/StudentWalletDetails"
import InstituteStudentWalletDetail from "pages/instituteUser/StudentWalletDetails/SingleView"
import InstituteStudentVarisangyaTransactions from "pages/instituteUser/StudentVarisangyaTransactions"
import InstituteStudentVarisangyaTransactionPrint from "pages/instituteUser/StudentVarisangyaTransactions/print"
import CreateInstituteStudentVarisangyaTransaction from "pages/instituteUser/StudentVarisangyaTransactions/Crud/Create"
import ClassLists from "pages/mahallUser/Classes/Datatable/classesList"
import AllStudents from "pages/mahallUser/Students"
import AllStaffs from "pages/mahallUser/Staffs"
import MadrasaAccountDetails from "pages/mahallUser/MadrasaAccounts"
import MadrasaCommitteeView from "pages/mahallUser/MadrasaCommitteeView"
import InstituteMadrasaAccountDetails from "pages/instituteUser/MadrasaAccounts"
import InstituteMadrasaCommitteeView from "pages/instituteUser/MadrasaCommitteeView"
import AllInstituteStudents from "pages/instituteUser/Students"
import InstituteClassLists from "pages/instituteUser/Classes/Datatable/classesList"
import AllInstituteStaffs from "pages/instituteUser/Staffs"
import MahallInstituteAssetDetails from "pages/mahallUser/MahallInstituteAssets/SingleView"
import MahallInstituteAssetItemCreate from "pages/mahallUser/MahallInstituteAssets/AssetItem/Create"
import MahallInstituteAssetItemDetails from "pages/mahallUser/MahallInstituteAssets/AssetItem/singleView/singleView"
import MahallInstituteAssetItemTransactionCreate from "pages/mahallUser/MahallInstituteAssets/AssetItem/singleView/itemTransactions/Create"
import MahallInstituteAssetItemTransactionDetails from "pages/mahallUser/MahallInstituteAssets/AssetItem/singleView/itemTransactions/singleView"
import InstituteAssetDetails from "pages/instituteUser/MahallInstituteAssets/SingleView"
import InstituteAssetItemCreate from "pages/instituteUser/MahallInstituteAssets/AssetItem/Create"
import InstituteAssetItemDetails from "pages/instituteUser/MahallInstituteAssets/AssetItem/singleView/singleView"
import InstituteAssetItemTransactionCreate from "pages/instituteUser/MahallInstituteAssets/AssetItem/singleView/itemTransactions/Create"
import InstituteAssetItemTransactionDetails from "pages/instituteUser/MahallInstituteAssets/AssetItem/singleView/itemTransactions/singleView"
import MadrasaAssetLists from "pages/mahallUser/MahallMadrasaAssets/Datatable/assetsList"
import InstituteAssetLists from "pages/instituteUser/MahallInstituteAssets/Datatable/assetsList"
import CreateMahallProgram from "pages/mahallUser/Programs/Create"
import MahallPrograme from "pages/mahallUser/Programs"
import InstituteWalletDetails from "pages/mahallUser/InstituteWallet/singleView"
import WalletDetails from "pages/instituteUser/InstituteWallet/singleView"
import AllInstituteWallets from "pages/mahallUser/InstituteWallet/Datatable"
import FamilyVarisangyaItemFilterList from "pages/mahallUser/FamilyVarisangyas/VarisangyaItems/Create"
import CreateTransactions from "pages/common/LandingPage/Transactions/Crud/Create"
import Deatails from "pages/common/LandingPage/Transactions/singleView/Deatails"
import Success from "pages/common/LandingPage/Transactions/payments/Success"

import Donations from "pages/admin/Donation"
import DonationDetails from "pages/admin/Donation/SingleView"
import SupportTicket from "pages/mahallUser/SupportTicket"
import SupportDetails from "pages/mahallUser/SupportTicket/SingleView"

import FamilyMemberVarisangyaItemFilterList from "pages/mahallUser/FamilyMemberVarisangyas/VarisangyaItems/Create"
import PublicNikahCertificate from "pages/common/Certificates/PublicNikahCertificate"
import SuperFeeds from "pages/mahallUser/SuperFeeds"
import UpdateSuperFeed from "pages/mahallUser/SuperFeeds/Crud/Update"
import CreateSuperFeed from "pages/mahallUser/SuperFeeds/Crud/Create"
import DeathCertificate from "pages/mahallUser/DeathRegistrations/SingleView/DeathCertificate"
import PublicDeathCertificate from "pages/common/Certificates/PublicDeathCertificate"
import PrivacyPolicy from "pages/mahallUser/Dashboard/MahallUserDashboard/PrivacyPolicy"
import PrintFamilyDetails from "pages/mahallUser/Families/SingleView/printData"
import SuperBanners from "pages/mahallUser/SuperBanner"
import CreateSuperBanner from "pages/mahallUser/SuperBanner/Crud/Create"
import UpdateSuperBanner from "pages/mahallUser/SuperBanner/Crud/Update"
import MainVarisangays from "pages/mahallUser/Varisangyas"
import VarisangyaPeriods from "pages/mahallUser/Varisangyas/varisangyaPeriod"
import VarisangyaPeriodItemDetails from "pages/mahallUser/Varisangyas/SingleView"
import AllVarisankyaWalletLists from "pages/mahallUser/Varisangyas/wallet"
import VarisangyaWalletDetails from "pages/mahallUser/Varisangyas/wallet/SingleView/family/FamilyIndex"
import FamilySingleDetails from "pages/mahallUser/Varisangyas/wallet/SingleView/member"
import AllVarisangyaTransactions from "pages/mahallUser/Varisangyas/Transactions"
import AllVarisangyaTransactionsCreate from "pages/mahallUser/Varisangyas/Transactions/CRUD"
import VarisangyaWalletPrint from "pages/mahallUser/Varisangyas/Transactions/print"

const commonRoute = [
  { path: "/dashboard", component: BasePage },
  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for super-admin only

const adminRoutes = [
  { path: "/dashboard", component: AdminDashboard },
  { path: "/settings", component: Settings },

  { path: "/countries", component: Country },
  { path: "/country/create", component: CreateCountry },
  { path: "/country/update/:id", component: UpdateCountry },

  { path: "/states", component: State },
  { path: "/state/create", component: CreateState },
  { path: "/state/update/:id", component: UpdateState },

  { path: "/districts", component: District },
  { path: "/district/create", component: CreateDistrict },
  { path: "/district/update/:id", component: UpdateDistrict },

  { path: "/villages", component: Village },
  { path: "/villages/:id", component: VillageDetails },
  { path: "/village/create", component: CreateVillage },
  { path: "/village/update/:id", component: UpdateVillage },

  { path: "/lsgs", component: Lsg },
  { path: "/lsg/create", component: CreateLsg },
  { path: "/lsg/update/:id", component: UpdateLsg },

  { path: "/mahalls", component: Mahall },
  { path: "/mahalls/:id", component: MahallDetails },
  { path: "/mahall/create", component: CreateMahall },
  { path: "/mahall/update/:id", component: UpdateMahall },

  { path: "/mahallusers", component: MahallUser },
  { path: "/mahallusers/:id", component: MahallUserDetails },
  { path: "/mahalluser/create", component: CreateMahallUser },
  { path: "/mahalluser/update/:id", component: UpdateMahallUser },
  { path: "/mahalluser/updatephone", component: UpdatePhone },

  { path: "/enquiries", component: Enquiries },
  { path: "/enquiries/:id", component: EnquiryDetails },
  { path: "/enquiry/create", component: CreateEnquiry },
  { path: "/enquiry/update/:id", component: UpdateEnquiry },

  { path: "/admins", component: Admins },

  { path: "/groups", component: Groups },
  { path: "/group/create", component: CreateGroup },
  { path: "/group/update/:id", component: UpdateGroup },

  { path: "/groupadmins", component: GroupAdmins },
  { path: "/groupadmin/create", component: CreateGroupAdmin },
  { path: "/groupadmin/update/:id", component: UpdateGroupAdmin },

  { path: "/admin/activitylogs", component: ActivityLogsAdmin },
  { path: "/admin/activitylog/:id", component: ActivityLogDetailsAdmin },

  { path: "/admin/donations", component: Donations },
  { path: "/admin/donations/:id", component: DonationDetails },

  { path: "/super-feeds", component: SuperFeeds },
  // { path: "/super-feeds/:id", component: FeedDetails },
  { path: "/super-feed/create", component: CreateSuperFeed },
  { path: "/super-feed/update/:id", component: UpdateSuperFeed },

  { path: "/super-banners", component: SuperBanners },
  // { path: "/super-feeds/:id", component: FeedDetails },
  { path: "/super-banner/create", component: CreateSuperBanner },
  { path: "/super-banner/update/:id", component: UpdateSuperBanner },
]

const mahallUserPremiumRoutes = [
  { path: "/dashboard", component: MahallUserDashboard },

  { path: "/families", component: MahallFamily },
  { path: "/families/:id", component: MahallFamilyDetails },
  { path: "/family/print/:id", component: PrintFamilyDetails },
  { path: "/family/create", component: CreateMahallFamily },
  { path: "/family/update/:id", component: UpdateMahallFamily },

  { path: "/familymembers", component: MahallFamilyMember },
  { path: "/familymembers/:id", component: MahallFamilyMemberDetails },
  { path: "/familymember/create", component: CreateMahallFamilyMember },
  {
    path: "/familymember/update/:id",
    component: UpdateMahallFamilyMember,
  },
  {
    path: "/familymember/update/phone/familymember",
    component: UpdatePhoneFamilyMember,
  },

  { path: "/mahallusers", component: MahallMahallUser },
  { path: "/mahallusers/:id", component: MahallMahallUserDetails },
  { path: "/mahalluser/create", component: CreateMahallMahallUser },
  { path: "/mahalluser/update/:id", component: UpdateMahallMahallUser },
  { path: "/mahalluser/updatephone", component: UpdateMahallMahallUserPhone },

  { path: "/premium", component: PagesPremium },

  { path: "/surveyusers", component: SurveyUsers },
  { path: "/surveyuser/create", component: CreateSurveyUser },
  { path: "/surveyuser/update/:id", component: UpdateSurveyUser },

  { path: "/institutes", component: MahallInstitute },
  { path: "/institutes/:id", component: InstituteMahallDetails },
  { path: "/institute/create", component: CreateMahallInstitute },
  { path: "/institute/update/:id", component: UpdateMahallInstitute },

  { path: "/institute/wallet/:id", component: InstituteWalletDetails },

  { path: "/instituteusers", component: MahallInstituteUser },
  { path: "/instituteusers/:id", component: InstituteUserMahallDetails },
  { path: "/instituteuser/create", component: CreateMahallInstituteUser },
  { path: "/instituteuser/update/:id", component: UpdateMahallInstituteUser },

  { path: "/madrasas", component: MahallMadrasas },
  { path: "/madrasa/:id", component: MahallMadrasaDetails },
  { path: "/madrasa/create/new", component: CreateMahallMadrasa },
  { path: "/madrasa/update/:id", component: UpdateMahallMadrasa },

  { path: "/ledgers", component: MahallLedger },
  { path: "/ledgers/:id", component: LedgerMahallDetails },

  { path: "/ledger/items", component: LedgerItem },
  { path: "/category/ledger/items", component: CategoryLedgerItems },

  { path: "/nikahs", component: MahallNikahRegistrations },
  { path: "/nikahs/:id", component: MahallNikahRegistrationDetails },
  { path: "/nikah/register", component: CreateMahallNikahRegistration },
  { path: "/nikah/update/:id", component: UpdateMahallNikahRegistration },

  { path: "/deaths", component: MahallDeathRegistrations },
  { path: "/deaths/:id", component: MahallDeathRegistrationDetails },
  { path: "/death/register", component: CreateMahallDeathRegistration },
  { path: "/death/update/:id", component: UpdateMahallDeathRegistration },
  { path: "/death/certificate/:id", component: DeathCertificate },

  { path: "/committees", component: MahallCommittee },
  { path: "/committees/:id", component: CommitteeMahallDetails },

  { path: "/committee/meetings", component: CommitteeMeetings },
  { path: "/committee/meetings/:id", component: MahallCommitteeMeetingDetails },
  {
    path: "/committee/meeting/create",
    component: CreateMahallCommitteeMeeting,
  },
  {
    path: "/committee/meeting/update/:id",
    component: UpdateMahallCommitteeMeeting,
  },

  { path: "/programes", component: MahallPrograme },
  { path: "/program/create", component: CreateMahallProgram },

  { path: "/mahallmasters", component: MahallMasterDetails },

  { path: "/accounts", component: MasterAccount },

  { path: "/zakats", component: MahallZakats },
  { path: "/zakats/:id", component: MahallZakatDetails },
  { path: "/zakat/register", component: CreateMahallZakat },
  { path: "/zakat/update/:id", component: UpdateMahallZakat },

  { path: "/unapprovedfamilies", component: UnapprovedFamily },
  { path: "/unapprovedfamilies/:id", component: UnapprovedFamilyDetails },
  { path: "/unapprovedfamily/update/:id", component: UpdateUnapprovedFamily },

  {
    path: "/unapprovedfamilymembers/:id",
    component: UnapprovedFamilyMemberDetails,
  },
  {
    path: "/unapprovedfamilymember/update/:id",
    component: UpdateUnapprovedFamilyMember,
  },

  { path: "/banners", component: Banner },
  { path: "/banners/:id", component: BannerDetails },
  { path: "/banner/create", component: CreateBanner },
  { path: "/banner/update/:id", component: UpdateBanner },

  { path: "/collections", component: Collections },
  { path: "/collections/:id", component: CollectionDetails },
  { path: "/collection/create", component: CreateBanner },
  { path: "/collection/update/:id", component: UpdateBanner },

  { path: "/collection-notifications", component: FilteredMemberNotifications },
  {
    path: "/collection-notifications/:id",
    component: FilteredMemberNotificationDetails,
  },
  {
    path: "/collection-notification/create",
    component: CreateFilteredMemberNotification,
  },
  {
    path: "/collection-notification/update/:id",
    component: UpdateFilteredMemberNotification,
  },
  {
    path: "/collection/notification/create",
    component: CreateNewFilteredMemberNotification,
  },
  {
    path: "/collection/notification/update/:id",
    component: UpdateNewFilteredMemberNotification,
  },
  { path: "/support-ticket", component: SupportTicket },
  { path: "/support-ticket/update/:id", component: SupportDetails },

  { path: "/notifications", component: IndividualNotifications },
  { path: "/notifications/:id", component: IndividualNotificationDetails },
  { path: "/notification/create", component: CreateIndividualNotification },
  { path: "/notification/update/:id", component: UpdateIndividualNotification },

  { path: "/activitylogs", component: ActivityLog },
  { path: "/activitylog/:id", component: ActivityLogDetails },

  { path: "/feeds", component: Feeds },
  { path: "/feeds/:id", component: FeedDetails },
  { path: "/feed/create", component: CreateFeed },
  { path: "/feed/update/:id", component: UpdateFeed },

  { path: "/family/varisangyas", component: FamilyVarisangyas },

  { path: "/varisangyas", component: MainVarisangays },
  { path: "/varisangya/:id/:type", component: VarisangyaPeriods },

  {
    path: "/varisangya-period/:id/:type/:periodId",
    component: VarisangyaPeriodItemDetails,
  },
  {
    path: "/varisangya-wallet/:id/:type",
    component: AllVarisankyaWalletLists,
  },

  {
    path: "/varisangya-wallet/details/:type/:walletId",
    component: FamilySingleDetails,
  },

  {
    path: "/varisangya/transactions/:varisnagyaId/:type",
    component: AllVarisangyaTransactions,
  },

  {
    path: "/varisangya-transactions/create",
    component: AllVarisangyaTransactionsCreate,
  },

  {
    path: "/varisangya/transaction/print/:id/:type",
    component: VarisangyaWalletPrint,
  },

  { path: "/family/varisangyas/:id", component: FamilyVarisangyaDetails },
  {
    path: "/family/varisangya/item/create",
    component: FamilyVarisangyaItemFilterList,
  },

  { path: "/family/wallet/details", component: AllFamilyWalletLists },
  { path: "/family/wallet/details/:id", component: FamilyWalletDetail },
  {
    path: "/family/varisangya/transactions",
    component: AllFamilyVarisangyaTransactions,
  },

  {
    path: "/family/varisangya/transaction/create",
    component: CreateFamilyVarisangyaTransaction,
  },
  {
    path: "/family/varisangya/transaction/print/:id",
    component: FamilyVarisangyaTransactionPrint,
  },

  { path: "/family/member/varisangyas", component: FamilyMemberVarisangyas },
  {
    path: "/family/member/varisangyas/:id",
    component: FamilyMemberVarisangyaDetails,
  },

  {
    path: "/family/member/varisangya/item/create",
    component: FamilyMemberVarisangyaItemFilterList,
  },

  {
    path: "/family/member/wallet/details",
    component: AllFamilyMemberWalletLists,
  },
  {
    path: "/family/member/wallet/details/:id",
    component: FamilyMemberWalletDetail,
  },

  {
    path: "/family/member/varisangya/transactions",
    component: AllFamilyMemberVarisangyaTransactions,
  },
  {
    path: "/family/member/varisangya/transaction/create",
    component: CreateFamilyMemberVarisangyaTransaction,
  },
  {
    path: "/family/member/varisangya/transaction/print/:id",
    component: MemberVarisangyaTransactionPrint,
  },

  { path: "/noc", component: NocCertificates },
  { path: "/noc/create", component: CreateNocCertificate },
  { path: "/noc/create/Others", component: CreateNocCertificate },
  {
    path: "/noc/create/Nikah_registration",
    component: CreateNikahRegistrationNoc,
  },
  { path: "/noc/update/:id", component: UpdateNocCertificate },

  { path: "/nikah/noc", component: NikahNocCertificates },

  { path: "/report/areas", component: AreaReports },
  { path: "/report/bloodgroups", component: BloodGroups },
  { path: "/report/orphans", component: Orphans },

  { path: "/nikah/certificate", component: NikahCertificate },
  { path: "/noc/certificate", component: NocCertificate },
  { path: "/receipt/:id", component: Receipt },

  { path: "/varisangya/splits", component: VarisangyaSplits },
  { path: "/varisangya/split/create", component: CreateVarisangyaSplit },
  { path: "/varisangya/split/update", component: UpdateVarisangyaSplit },

  { path: "/institute/categories", component: InstituteCategories },

  { path: "/account/madrasa", component: MadrasaAccountDetails },
  { path: "/committee/madrasa", component: MadrasaCommitteeView },

  { path: "/class/:id", component: ClassDetails },
  { path: "/classes", component: ClassLists },

  { path: "/division/:id", component: DivisionDetails },

  { path: "/students", component: AllStudents },
  { path: "/student/create", component: CreateStudent },
  { path: "/student/update/:id", component: UpdateStudent },
  { path: "/student/:id", component: StudentDetails },

  { path: "/staffs", component: AllStaffs },

  {
    path: "/student/attendance/details/:id",
    component: StudentAttendanceDetails,
  },

  { path: "/student/varisangya/lists", component: StudentVarisangyas },
  { path: "/student/varisangya/:id", component: StudentVarisangyaDetails },
  { path: "/student/wallet/list", component: AllStudentWalletLists },
  { path: "/student/wallet/details/:id", component: StudentWalletDetail },
  {
    path: "/student/fee/transactions",
    component: StudentVarisangyaTransactions,
  },
  {
    path: "/student/varisangya/transaction/create",
    component: CreateStudentVarisangyaTransaction,
  },
  {
    path: "/student/varisangya/transaction/print/:id",
    component: StudentVarisangyaTransactionPrint,
  },

  {
    path: "/mahall/madrasa/asset/list",
    component: MadrasaAssetLists,
  },
  {
    path: "/mahall/institute/asset/:id",
    component: MahallInstituteAssetDetails,
  },
  {
    path: "/mahall/institute/assetItem/create",
    component: MahallInstituteAssetItemCreate,
  },
  {
    path: "/mahall/institute/assetItem/:id",
    component: MahallInstituteAssetItemDetails,
  },
  {
    path: "/mahall/institute/assetItem/add/entry",
    component: MahallInstituteAssetItemTransactionCreate,
  },
  {
    path: "/mahall/institute/assetItem/transaction/:id",
    component: MahallInstituteAssetItemTransactionDetails,
  },

  { path: "/institute/wallets", component: AllInstituteWallets },
  { path: "/support-ticket", component: SupportTicket },

  // { path: "/super-feeds", component: SuperFeeds },
  // { path: "/super-feeds/:id", component: FeedDetails },
  { path: "/super-feed/create", component: CreateSuperFeed },
  { path: "/super-feed/update/:id", component: UpdateSuperFeed },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const mahallUserBasicRoutes = [
  { path: "/dashboard", component: MahallUserDashboard },

  { path: "/families", component: MahallFamily },
  { path: "/families/:id", component: MahallFamilyDetails },
  { path: "/family/create", component: CreateMahallFamily },
  { path: "/family/update/:id", component: UpdateMahallFamily },

  { path: "/familymembers", component: MahallFamilyMember },
  { path: "/familymembers/:id", component: MahallFamilyMemberDetails },
  { path: "/familymember/create", component: CreateMahallFamilyMember },
  {
    path: "/familymember/update/:id",
    component: UpdateMahallFamilyMember,
  },
  {
    path: "/familymember/update/phone/familymember",
    component: UpdatePhoneFamilyMember,
  },

  { path: "/mahallusers", component: MahallMahallUser },
  { path: "/mahallusers/:id", component: MahallMahallUserDetails },
  { path: "/mahalluser/create", component: CreateMahallMahallUser },
  { path: "/mahalluser/update/:id", component: UpdateMahallMahallUser },
  { path: "/mahalluser/updatephone", component: UpdateMahallMahallUserPhone },

  { path: "/premium", component: PagesPremium },

  { path: "/surveyusers", component: SurveyUsers },
  { path: "/surveyuser/create", component: CreateSurveyUser },
  { path: "/surveyuser/update/:id", component: UpdateSurveyUser },

  { path: "/support-ticket", component: SupportTicket },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const surveyUserRoutes = [
  { path: "/dashboard", component: SurveyUserDashboard },

  { path: "/families", component: SurveyUserFamily },
  { path: "/families/:id", component: SurveyUserFamilyDetails },
  { path: "/family/create", component: CreateSurveyUserFamily },
  { path: "/family/update/:id", component: UpdateSurveyUserFamily },

  { path: "/familymembers", component: SurveyUserFamilyMember },
  { path: "/familymembers/:id", component: SurveyUserFamilyMemberDetails },
  { path: "/familymember/create", component: CreateSurveyUserFamilyMember },
  { path: "/familymember/update/:id", component: UpdateSurveyUserFamilyMember },
  {
    path: "/familymember/update/phone/familymember",
    component: UpdatePhoneFamilyMember,
  },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const groupAdminRoutes = [
  { path: "/dashboard", component: GroupAdminDashboard },

  { path: "/mahalls", component: GroupAdminMahall },
  { path: "/mahalls/:id", component: GroupAdminMahallDetails },
  { path: "/mahall/create", component: CreateGroupAdminMahall },
  { path: "/mahall/update/:id", component: UpdateGroupAdminMahall },

  { path: "/mahall/notifications", component: MahallNotifications },
  { path: "/mahall/notifications/:id", component: MahallNotificationDetails },
  { path: "/mahall/notification/create", component: CreateMahallNotification },
  {
    path: "/mahall/notification/update/:id",
    component: UpdateMahallNotification,
  },
  //
  // { path: "/super-feeds", component: SuperFeeds },
  // { path: "/super-feeds/:id", component: FeedDetails },
  // { path: "/super-feed/create", component: CreateSuperFeed },
  // { path: "/super-feed/update/:id", component: UpdateSuperFeed },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const instituteUserRoutes = [
  { path: "/dashboard", component: InstituteUserDashboard },
  { path: "/ledgers/:id", component: InstituteLedgerDetails },
  { path: "/committees/:id", component: InstituteCommitteeDetails },

  {
    path: "/committee/meetings/:id",
    component: InstituteCommitteeMeetingDetails,
  },
  {
    path: "/committee/meeting/create",
    component: CreateInstituteCommitteeMeeting,
  },
  {
    path: "/committee/meeting/update/:id",
    component: UpdateInstituteCommitteeMeeting,
  },

  { path: "/class/:id", component: InstituteClassDetails },
  { path: "/classes", component: InstituteClassLists },

  { path: "/division/:id", component: InstituteDivisionDetails },

  { path: "/students", component: AllInstituteStudents },
  { path: "/student/create", component: InstituteCreateStudent },
  { path: "/student/update/:id", component: InstituteUpdateStudent },
  { path: "/student/:id", component: InstituteStudentDetails },

  {
    path: "/student/attendance/details/:id",
    component: InstituteStudentAttendanceDetails,
  },

  { path: "/student/varisangya/lists", component: InstituteStudentVarisangyas },
  {
    path: "/student/varisangya/:id",
    component: InstituteStudentVarisangyaDetails,
  },
  { path: "/student/wallet/list", component: AllInstituteStudentWalletLists },
  {
    path: "/student/wallet/details/:id",
    component: InstituteStudentWalletDetail,
  },
  {
    path: "/student/fee/transactions",
    component: InstituteStudentVarisangyaTransactions,
  },
  {
    path: "/student/varisangya/transaction/create",
    component: CreateInstituteStudentVarisangyaTransaction,
  },
  {
    path: "/student/varisangya/transaction/print/:id",
    component: InstituteStudentVarisangyaTransactionPrint,
  },

  { path: "/account/madrasa", component: InstituteMadrasaAccountDetails },
  { path: "/committee/madrasa", component: InstituteMadrasaCommitteeView },
  { path: "/staffs", component: AllInstituteStaffs },

  {
    path: "/mahall/institute/asset/list",
    component: InstituteAssetLists,
  },
  {
    path: "/mahall/institute/asset/:id",
    component: InstituteAssetDetails,
  },
  {
    path: "/mahall/institute/assetItem/create",
    component: InstituteAssetItemCreate,
  },
  {
    path: "/mahall/institute/assetItem/:id",
    component: InstituteAssetItemDetails,
  },
  {
    path: "/mahall/institute/assetItem/add/entry",
    component: InstituteAssetItemTransactionCreate,
  },
  {
    path: "/mahall/institute/assetItem/transaction/:id",
    component: InstituteAssetItemTransactionDetails,
  },

  { path: "/institute/wallet/:id", component: WalletDetails },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const varisangyaUserRoutes = [
  { path: "/dashboard", component: VarisangyaUserDashboard },

  { path: "/family/varisangyas", component: UserFamilyVarisangyas },
  { path: "/family/varisangyas/:id", component: UserFamilyVarisangyaDetails },
  { path: "/family/varisangya/create", component: UserCreateFamilyVarisangya },
  {
    path: "/family/varisangya/update/:id",
    component: UserUpdateFamilyVarisangya,
  },

  { path: "/family/wallet/details", component: AllUserFamilyWalletDetail },
  { path: "/family/wallet/details/:id", component: UserFamilyWalletDetail },

  {
    path: "/family/varisangya/transactions",
    component: UserFamilyVarisangyaWallets,
  },
  {
    path: "/family/varisangya/transaction/create",
    component: UserCreateFamilyVarisangyaWallet,
  },
  // {
  //   path: '/family/varisangya/transaction/print/:id',
  //   component: VarisangyaTransactionPrint,
  // },

  {
    path: "/family/member/varisangyas",
    component: UserFamilyMemberVarisangyas,
  },
  {
    path: "/family/member/varisangyas/:id",
    component: UserFamilyMemberVarisangyaDetails,
  },
  {
    path: "/family/member/varisangya/create",
    component: UserCreateFamilyVarisangya,
  },
  {
    path: "/family/member/varisangya/update/:id",
    component: UserUpdateFamilyVarisangya,
  },

  {
    path: "/family/member/wallet/details",
    component: AllUserFamilyMemberWalletDetail,
  },
  {
    path: "/family/member/wallet/details/:id",
    component: UserFamilyMemberWalletDetail,
  },

  {
    path: "/family/member/varisangya/transactions",
    component: UserFamilyMemberVarisangyaWallets,
  },
  {
    path: "/family/member/varisangya/transaction/create",
    component: UserCreateFamilyMemberVarisangyaWallet,
  },
  // {
  //   path: '/family/member/varisangya/transaction/print/:id',
  //   component: MemberVarisangyaTransactionPrint,
  // },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/role", component: Role },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/ui-modals", component: UiModal },
  { path: "/home/en", component: LandingPage },
  { path: "/home/ml", component: LandingPage },
  { path: "/transactions", component: CreateTransactions },
  { path: "/transaction-details", component: Deatails },
  { path: "/success-page", component: Success },
  { path: "/payment-fail", component: Deatails },
  { path: "/about", component: AboutUs },
  { path: "/terms", component: TermsAndCondition },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/public-certificate/noc", component: PublicNocCertificate },
  { path: "/public-certificate/nikah", component: PublicNikahCertificate },
  { path: "/public-certificate/death", component: PublicDeathCertificate },

  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
]

export {
  publicRoutes,
  adminRoutes,
  mahallUserPremiumRoutes,
  mahallUserBasicRoutes,
  surveyUserRoutes,
  groupAdminRoutes,
  instituteUserRoutes,
  varisangyaUserRoutes,
  commonRoute,
}
