import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYA_CARDS,
  UPDATE_MAIN_FAMILY_VARISANGYA,
  CREATE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA,
  GET_FAMILY_VARISANGYA_DETAIL,
  GET_FAMILY_VARISANGYAS_SUCCESS,
  GET_FAMILY_VARISANGYAS_FAIL,
  GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_VARISANGYA_CARDS_FAIL,
  CREATE_FAMILY_VARISANGYA_SUCCESS,
  CREATE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  UPDATE_FAMILY_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_FAIL,
  DELETE_FAMILY_VARISANGYA_SUCCESS,
  DELETE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  REFRESH_FAMILY_VARISANGYA,
  REFRESH_FAMILY_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  CREATE_MAIN_VARISANGYA,
  CREATE_MAIN_VARISANGYA_SUCCESS,
  CREATE_MAIN_VARISANGYA_FAIL,
  UPDATE_MAIN_VARISANGYA,
  UPDATE_MAIN_VARISANGYA_SUCCESS,
  UPDATE_MAIN_VARISANGYA_FAIL,
  DELETE_MAIN_VARISANGYA,
  DELETE_MAIN_VARISANGYA_SUCCESS,
  DELETE_MAIN_VARISANGYA_FAIL,
  GET_ALL_VARISANGYA_PERIOD,
  GET_ALL_VARISANGYA_PERIOD_SUCCESS,
  GET_ALL_VARISANGYA_PERIOD_FAIL,
  AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS,
  AUTO_GENERATE_VARISANGYA_PERIOD_FAIL,
  AUTO_GENERATE_VARISANGYA_PERIOD,
  CREATE_VARISANGYA_PERIOD,
  CREATE_VARISANGYA_PERIOD_SUCCESS,
  CREATE_VARISANGYA_PERIOD_FAIL,
  UPDATE_VARISANGYA_PERIOD,
  UPDATE_VARISANGYA_PERIOD_SUCCESS,
  UPDATE_VARISANGYA_PERIOD_FAIL,
  DELETE_VARISANGYA_PERIOD,
  DELETE_VARISANGYA_PERIOD_SUCCESS,
  DELETE_VARISANGYA_PERIOD_FAIL,
  GET_VARISANGYA_PERIOD_DETAIL,
  GET_VARISANGYA_PERIOD_DETAIL_SUCCESS,
  GET_VARISANGYA_PERIOD_DETAIL_FAIL,
  GET_ALL_VARISANGYA_PERIOD_ITEMS,
  GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS,
  GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL,
  REFRESH_VARISANGYA_PERIOD_ITEM,
  REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS,
  REFRESH_VARISANGYA_PERIOD_ITEM_FAIL,
  DELETE_VARISANGYA_PERIOD_ITEM,
  DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS,
  DELETE_VARISANGYA_PERIOD_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  varisangyaPeriod: [],
  familyVarisangyaCards: {},
  varisangyaPeriodDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  // autoGenerateLoading: false,
  refreshLoading: false,
  createdVarisangya: {},
  allVarisangyaPerioditems: [],
}

const VarisangyaPeriod = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_VARISANGYA_PERIOD:
    case GET_FAMILY_VARISANGYA_CARDS:
    case UPDATE_VARISANGYA_PERIOD:
    case CREATE_VARISANGYA_PERIOD:
    case DELETE_VARISANGYA_PERIOD:
    case DELETE_VARISANGYA_PERIOD_ITEM:
    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER:
    case AUTO_GENERATE_VARISANGYA_PERIOD:
      return {
        ...state,
        loading: true,
      }
    case GET_VARISANGYA_PERIOD_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_ALL_VARISANGYA_PERIOD_ITEMS:
      return {
        ...state,
        loading: true,
      }

    case REFRESH_VARISANGYA_PERIOD_ITEM:
      return {
        ...state,
        refreshLoading: true,
      }

    case GET_ALL_VARISANGYA_PERIOD_SUCCESS:
      return {
        ...state,
        varisangyaPeriod: action.payload,
        loading: false,
      }
    case GET_ALL_VARISANGYA_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_VARISANGYA_CARDS_SUCCESS:
      return {
        ...state,
        familyVarisangyaCards: action.payload,
        loading: false,
      }
    case GET_FAMILY_VARISANGYA_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_PERIOD_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        varisangyaPeriod: {
          ...state.varisangyaPeriod,
          results: [...state.varisangyaPeriod.results, action.payload],
        },
        createdVarisangya: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VARISANGYA_PERIOD_DETAIL_SUCCESS:
      return {
        ...state,
        varisangyaPeriodDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_VARISANGYA_PERIOD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS:
      return {
        ...state,
        allVarisangyaPerioditems: action.payload,
        loading: false,
      }

    case GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_VARISANGYA_PERIOD_SUCCESS:
      return {
        ...state,
        // familyVarisangyaDetail: action.payload,
        varisangyaPeriod: {
          results: state.varisangyaPeriod.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_VARISANGYA_PERIOD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_VARISANGYA_PERIOD_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        varisangyaPeriod: {
          results: state?.varisangyaPeriod?.results.filter(
            familyVarisangya => familyVarisangya.id !== action.payload
          ),
        },
        loading: false,
      }
    case DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS:
      console.log(action.payload)
      console.log(state?.allVarisangyaPerioditems)

      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        allVarisangyaPerioditems: {
          results: state?.allVarisangyaPerioditems?.results.filter(
            familyVarisangya => familyVarisangya.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_VARISANGYA_PERIOD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS:
      return {
        ...state,
        // familyVarisangyas: action.payload,
        loading: false,
      }
    case AUTO_GENERATE_VARISANGYA_PERIOD_FAIL:
      return {
        ...state,
        // error: action.payload,
        loading: false,
      }

    case REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS:
      return {
        ...state,
        allVarisangyaPerioditems: action.payload,
        refreshLoading: false,
      }
    case REFRESH_VARISANGYA_PERIOD_ITEM_FAIL:
      return {
        ...state,
        // error: action.payload,
        refreshLoading: false,
      }

    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default VarisangyaPeriod
