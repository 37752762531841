/* FAMILY_VARISANGYAS */
export const GET_ALL_VARISANGYA_PERIOD = "GET_ALL_VARISANGYA_PERIOD"
export const GET_ALL_VARISANGYA_PERIOD_SUCCESS =
  "GET_ALL_VARISANGYA_PERIOD_SUCCESS"
export const GET_ALL_VARISANGYA_PERIOD_FAIL = "GET_ALL_VARISANGYA_PERIOD_FAIL"

/* FAMILY_VARISANGYAS CARDS*/
export const GET_FAMILY_VARISANGYA_CARDS = "GET_FAMILY_VARISANGYA_CARDS"
export const GET_FAMILY_VARISANGYA_CARDS_SUCCESS =
  "GET_FAMILY_VARISANGYA_CARDS_SUCCESS"
export const GET_FAMILY_VARISANGYA_CARDS_FAIL =
  "GET_FAMILY_VARISANGYA_CARDS_FAIL"

/* FAMILY_VARISANGYA  DETAIL*/
export const GET_VARISANGYA_PERIOD_DETAIL = "GET_VARISANGYA_PERIOD_DETAIL"
export const GET_VARISANGYA_PERIOD_DETAIL_SUCCESS =
  "GET_VARISANGYA_PERIOD_DETAIL_SUCCESS"
export const GET_VARISANGYA_PERIOD_DETAIL_FAIL =
  "GET_VARISANGYA_PERIOD_DETAIL_FAIL"

// period items
export const GET_ALL_VARISANGYA_PERIOD_ITEMS = "GET_ALL_VARISANGYA_PERIOD_ITEMS"
export const GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS =
  "GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS"
export const GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL =
  "GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL"

export const DELETE_VARISANGYA_PERIOD_ITEM = "DELETE_VARISANGYA_PERIOD_ITEM"
export const DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS =
  "DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS"
export const DELETE_VARISANGYA_PERIOD_ITEM_FAIL =
  "DELETE_VARISANGYA_PERIOD_ITEM_FAIL"

/**
 * add FAMILY_VARISANGYA
 */
export const CREATE_VARISANGYA_PERIOD = "CREATE_VARISANGYA_PERIOD"
export const CREATE_VARISANGYA_PERIOD_SUCCESS =
  "CREATE_VARISANGYA_PERIOD_SUCCESS"
export const CREATE_VARISANGYA_PERIOD_FAIL = "CREATE_VARISANGYA_PERIOD_FAIL"

/**
 * Edit FAMILY_VARISANGYA
 */
export const UPDATE_VARISANGYA_PERIOD = "UPDATE_VARISANGYA_PERIOD"
export const UPDATE_VARISANGYA_PERIOD_SUCCESS =
  "UPDATE_VARISANGYA_PERIOD_SUCCESS"
export const UPDATE_VARISANGYA_PERIOD_FAIL = "UPDATE_VARISANGYA_PERIOD_FAIL"

/**
 * Delete FAMILY_VARISANGYA
 */
export const DELETE_VARISANGYA_PERIOD = "DELETE_VARISANGYA_PERIOD"
export const DELETE_VARISANGYA_PERIOD_SUCCESS =
  "DELETE_VARISANGYA_PERIOD_SUCCESS"
export const DELETE_VARISANGYA_PERIOD_FAIL = "DELETE_VARISANGYA_PERIOD_FAIL"

// AUTO_GENERATE_FAMILY_VARISANGYAS
export const AUTO_GENERATE_VARISANGYA_PERIOD = "AUTO_GENERATE_VARISANGYA_PERIOD"
export const AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS =
  "AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS"
export const AUTO_GENERATE_VARISANGYA_PERIOD_FAIL =
  "AUTO_GENERATE_VARISANGYA_PERIOD_FAIL"

// REFRESH_FAMILY_VARISANGYA
export const REFRESH_VARISANGYA_PERIOD_ITEM = "REFRESH_VARISANGYA_PERIOD_ITEM"
export const REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS =
  "REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS"
export const REFRESH_VARISANGYA_PERIOD_ITEM_FAIL =
  "REFRESH_VARISANGYA_PERIOD_ITEM_FAIL"

// UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER"
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS"
export const UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL =
  "UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL"
