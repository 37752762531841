/* FAMILY_VARISANGYA_WALLET */
export const GET_FILTERED_MEMBERS = "GET_FILTERED_MEMBERS"
export const GET_FILTERED_MEMBERS_SUCCESS = "GET_FILTERED_MEMBERS_SUCCESS"
export const GET_FILTERED_MEMBERS_FAIL = "GET_FILTERED_MEMBERS_FAIL"

/**
 * add FAMILY_VARISANGYA_WALLET
 */
export const UPDATE_FILTERED_WALLETS = "UPDATE_FILTERED_WALLETS"
export const UPDATE_FILTERED_WALLETS_SUCCESS = "UPDATE_FILTERED_WALLETS_SUCCESS"
export const UPDATE_FILTERED_WALLETS_FAIL = "UPDATE_FILTERED_WALLETS_FAIL"

/**
 * VARISANGYA_WALLET_DETAIL
 */
export const GET_VARISANGYA_WALLET_DETAIL = "GET_VARISANGYA_WALLET_DETAIL"
export const GET_VARISANGYA_WALLET_DETAIL_SUCCESS =
  "GET_VARISANGYA_WALLET_DETAIL_SUCCESS"
export const GET_VARISANGYA_WALLET_DETAIL_FAIL =
  "GET_VARISANGYA_WALLET_DETAIL_FAIL"

export const CREATE_FAMILY_VARISANGYA_WALLET_NEW =
  "CREATE_FAMILY_VARISANGYA_WALLET_NEW"
export const CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS =
  "CREATE_FAMILY_VARISANGYA_WALLET_NEW_SUCCESS"
export const CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL =
  "CREATE_FAMILY_VARISANGYA_WALLET_NEW_FAIL"

export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW"
export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_SUCCESS"
export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_NEW_FAIL"

/**
 
 * FAMILY_VARISANGYA_WALLET_VIEW
 */
export const GET_ALL_FAMILY_VARISANGYA_WALLET =
  "GET_ALL_FAMILY_VARISANGYA_WALLET"
export const GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS =
  "GET_ALL_FAMILY_VARISANGYA_WALLET_SUCCESS"
export const GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL =
  "GET_ALL_FAMILY_VARISANGYA_WALLET_FAIL"

export const GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET =
  "GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET"
export const GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS =
  "GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS"
export const GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL =
  "GET_ALL_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL"

/**
 * add CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL
 */
export const CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL =
  "CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL"

/* Delete FAMILY_VARISANGYA_WALLET */
export const DELETE_VARISANGYA_WALLET = "DELETE_VARISANGYA_WALLET"
export const DELETE_VARISANGYA_WALLET_SUCCESS =
  "DELETE_VARISANGYA_WALLET_SUCCESS"
export const DELETE_VARISANGYA_WALLET_FAIL = "DELETE_VARISANGYA_WALLET_FAIL"

export const GET_VARISANGYA_TRANSACTION_DETAIL =
  "GET_VARISANGYA_TRANSACTION_DETAIL"
export const GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS =
  "GET_VARISANGYA_TRANSACTION_DETAIL_SUCCESS"
export const GET_VARISANGYA_TRANSACTION_DETAIL_FAIL =
  "GET_VARISANGYA_TRANSACTION_DETAIL_FAIL"
