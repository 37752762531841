import React from "react"
import { Col, Container, Row } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import CreateFamilyVarisangyaTransaction from "./family/Create"
import CreateFamilyMemberVarisangyaTransaction from "./member/Create"

function AllVarisangyaTransactionsCreate() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))
  const location = useLocation()

  return (
    <>
      <MetaTags>
        <title>Varisangyas| Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വരിസംഖ്യ" : "Varisangya"}
          breadcrumbItem={language === "mal" ? "ഇടപാടുകൾ " : "Transactions"}
        />
        <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                  {/* <BreadcrumbItem>
                    <Link to="#">{language === "mal" ? "ഡാഷ്ബോർഡ്" : "Dashboard"}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{
                      language === "mal" ? "വരിസംഖ്യകൾ" : "Varisangyas"
                    }</Link>
                  </BreadcrumbItem> */}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Container fluid className="sm:p-0">
          <div className="container-fluid sm:p-0">
            {location?.state?.type === "family" ? (
              <CreateFamilyVarisangyaTransaction />
            ) : (
              <CreateFamilyMemberVarisangyaTransaction />
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default AllVarisangyaTransactionsCreate
