import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYAS_SUCCESS,
  GET_FAMILY_VARISANGYAS_FAIL,
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_VARISANGYA_CARDS_FAIL,
  GET_FAMILY_VARISANGYA_DETAIL,
  GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  CREATE_FAMILY_VARISANGYA,
  CREATE_FAMILY_VARISANGYA_SUCCESS,
  CREATE_FAMILY_VARISANGYA_FAIL,
  UPDATE_MAIN_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_FAIL,
  DELETE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA_SUCCESS,
  DELETE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  REFRESH_FAMILY_VARISANGYA,
  REFRESH_FAMILY_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
  GET_ALL_MAIN_VARISANGYAS,
  GET_ALL_MAIN_VARISANGYAS_SUCCESS,
  GET_ALL_MAIN_VARISANGYAS_FAIL,
  CREATE_MAIN_VARISANGYA,
  CREATE_MAIN_VARISANGYA_SUCCESS,
  CREATE_MAIN_VARISANGYA_FAIL,
  UPDATE_MAIN_VARISANGYA,
  UPDATE_MAIN_VARISANGYA_SUCCESS,
  UPDATE_MAIN_VARISANGYA_FAIL,
  DELETE_MAIN_VARISANGYA,
  DELETE_MAIN_VARISANGYA_SUCCESS,
  DELETE_MAIN_VARISANGYA_FAIL,
  GET_ALL_VARISANGYA_PERIOD,
  GET_ALL_VARISANGYA_PERIOD_SUCCESS,
  GET_ALL_VARISANGYA_PERIOD_FAIL,
  AUTO_GENERATE_VARISANGYA,
  AUTO_GENERATE_VARISANGYA_SUCCESS,
  AUTO_GENERATE_VARISANGYA_FAIL,
  AUTO_GENERATE_VARISANGYA_PERIOD,
  AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS,
  AUTO_GENERATE_VARISANGYA_PERIOD_FAIL,
  CREATE_VARISANGYA_PERIOD,
  CREATE_VARISANGYA_PERIOD_SUCCESS,
  CREATE_VARISANGYA_PERIOD_FAIL,
  UPDATE_VARISANGYA_PERIOD,
  UPDATE_VARISANGYA_PERIOD_SUCCESS,
  UPDATE_VARISANGYA_PERIOD_FAIL,
  DELETE_VARISANGYA_PERIOD,
  DELETE_VARISANGYA_PERIOD_SUCCESS,
  DELETE_VARISANGYA_PERIOD_FAIL,
  GET_VARISANGYA_PERIOD_DETAIL,
  GET_VARISANGYA_PERIOD_DETAIL_SUCCESS,
  GET_VARISANGYA_PERIOD_DETAIL_FAIL,
  GET_ALL_VARISANGYA_PERIOD_ITEMS,
  GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS,
  GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL,
  REFRESH_VARISANGYA_PERIOD_ITEM,
  REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS,
  REFRESH_VARISANGYA_PERIOD_ITEM_FAIL,
  DELETE_VARISANGYA_PERIOD_ITEM,
  DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS,
  DELETE_VARISANGYA_PERIOD_ITEM_FAIL,
} from "./actionTypes"

export const getAllVarisangyaPeriod = (
  searchText,
  page,
  limit,
  variansangyaId
) => ({
  type: GET_ALL_VARISANGYA_PERIOD,
  payload: {
    searchText,
    page,
    limit,
    variansangyaId,
  },
})
export const getAllVarisangyaPeriodSuccess = varisangyaPeriod => ({
  type: GET_ALL_VARISANGYA_PERIOD_SUCCESS,
  payload: varisangyaPeriod,
})
export const getAllVarisangyaPeriodFail = error => ({
  type: GET_ALL_VARISANGYA_PERIOD_FAIL,
  payload: error,
})

export const getFamilyVarisangyaCards = () => ({
  type: GET_FAMILY_VARISANGYA_CARDS,
})
export const getFamilyVarisangyaCardsSuccess = familyVarisangyaCards => ({
  type: GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  payload: familyVarisangyaCards,
})
export const getFamilyVarisangyaCardsFail = error => ({
  type: GET_FAMILY_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getVarisangyaPeriodDetail = varisangyaPeriodId => ({
  type: GET_VARISANGYA_PERIOD_DETAIL,
  varisangyaPeriodId,
})

export const getVarisangyaPeriodDetailSuccess = varisangyaPeriodDetail => ({
  type: GET_VARISANGYA_PERIOD_DETAIL_SUCCESS,
  payload: varisangyaPeriodDetail,
})

export const getVarisangyaPeriodDetailFail = error => ({
  type: GET_VARISANGYA_PERIOD_DETAIL_FAIL,
  payload: error,
})

export const getAllVarisangyaPeriodItems = (
  varisangyaPeriodId,
  page,
  limit,
  searchText,
  type
) => ({
  type: GET_ALL_VARISANGYA_PERIOD_ITEMS,
  payload: { varisangyaPeriodId, page, limit, searchText, type },
})

export const getAllVarisangyaPeriodItemsSuccess = allVarisangyaPerioditems => ({
  type: GET_ALL_VARISANGYA_PERIOD_ITEMS_SUCCESS,
  payload: allVarisangyaPerioditems,
})

export const getAllVarisangyaPeriodItemsFail = error => ({
  type: GET_ALL_VARISANGYA_PERIOD_ITEMS_FAIL,
  payload: error,
})

export const deleteVarisangyaPeriodItem = (
  varisangyaPeriodItemId,
  history,
  type,
  periodId
) => ({
  type: DELETE_VARISANGYA_PERIOD_ITEM,
  payload: { varisangyaPeriodItemId, history, type, periodId },
})

export const deleteVarisangyaPeriodItemSuccess = allVarisangyaPerioditems => ({
  type: DELETE_VARISANGYA_PERIOD_ITEM_SUCCESS,
  payload: allVarisangyaPerioditems,
})

export const deleteVarisangyaPeriodItemFail = error => ({
  type: DELETE_VARISANGYA_PERIOD_ITEM_FAIL,
  payload: error,
})

export const createVarisangyaPeriod = (varisangyaPeriodData, history) => ({
  type: CREATE_VARISANGYA_PERIOD,
  payload: { varisangyaPeriodData, history },
})

export const createVarisangyaPeriodSuccess = mainVarisangyas => ({
  type: CREATE_VARISANGYA_PERIOD_SUCCESS,
  payload: mainVarisangyas,
})

export const createVarisangyaPeriodFail = error => ({
  type: CREATE_VARISANGYA_PERIOD_FAIL,
  payload: error,
})

export const updateVarisangyaPeriod = (
  varisangyaPeriodData,
  varisangyaPeriodId,
  history
) => ({
  type: UPDATE_VARISANGYA_PERIOD,
  payload: { varisangyaPeriodData, varisangyaPeriodId, history },
})

export const updateVarisangyaPeriodSuccess = mainVarisangyas => ({
  type: UPDATE_VARISANGYA_PERIOD_SUCCESS,
  payload: mainVarisangyas,
})

export const updateVarisangyaPeriodFail = error => ({
  type: UPDATE_VARISANGYA_PERIOD_FAIL,
  payload: error,
})

export const deleteVarisangyaPeriod = (varisangyaPeriodId, history) => ({
  type: DELETE_VARISANGYA_PERIOD,
  payload: { varisangyaPeriodId, history },
})

export const deleteVarisangyaPeriodSuccess = mainVarisangyas => ({
  type: DELETE_VARISANGYA_PERIOD_SUCCESS,
  payload: mainVarisangyas,
})

export const deleteVarisangyaPeriodFail = error => ({
  type: DELETE_VARISANGYA_PERIOD_FAIL,
  payload: error,
})

export const autoGenerateVarisangyaPeriod = varisangyaId => ({
  type: AUTO_GENERATE_VARISANGYA_PERIOD,
  payload: { varisangyaId },
})

export const autoGenerateVarisangyaPeriodSuccess = varisangyaPeriod => ({
  type: AUTO_GENERATE_VARISANGYA_PERIOD_SUCCESS,
  payload: varisangyaPeriod,
})

export const autoGenerateVarisangyaPeriodFail = error => ({
  type: AUTO_GENERATE_VARISANGYA_PERIOD_FAIL,
  payload: error,
})

export const refreshVarisangyaPeriodItem = (varisangyaPeriodId, type) => ({
  type: REFRESH_VARISANGYA_PERIOD_ITEM,
  payload: { varisangyaPeriodId, type },
})

export const refreshVarisangyaPeriodItemSuccess = allVarisangyaPerioditems => ({
  type: REFRESH_VARISANGYA_PERIOD_ITEM_SUCCESS,
  payload: allVarisangyaPerioditems,
})

export const refreshVarisangyaPeriodItemFail = error => ({
  type: REFRESH_VARISANGYA_PERIOD_ITEM_FAIL,
  payload: error,
})

export const updateFamilyVarisangyaAmountByFilter = (
  familyVarisangyaAmuontByFilter,
  history
) => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  payload: { familyVarisangyaAmuontByFilter, history },
})

export const updateFamilyVarisangyaAmountByFilterSuccess =
  familyVarisangyaAmuontByFilter => ({
    type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
    payload: familyVarisangyaAmuontByFilter,
  })

export const updateFamilyVarisangyaAmountByFilterFail = error => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  payload: error,
})
