import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import AllVarisangyaPeriods from "./SingleViewDatatableTables"

function VarisangyaPeriods() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <>
      <MetaTags>
        <title>Varisangya Period| Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "വരിസംഗ്യ കാലഘട്ടം " : "Varisangya Periods"
          }
          breadcrumbItem={
            language === "mal"
              ? "വരിസംഗ്യ കാലഘട്ടം ലിസ്റ്റ് "
              : "Varisangya Periods List"
          }
        />
        {/* <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                <BreadcrumbItem>
                    <Link to="#">{language === "mal" ? "ഡാഷ്ബോർഡ്" : "Dashboard"}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{
                      language === "mal" ? "വരിസംഖ്യകൾ" : "Varisangyas"
                    }</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row> */}
        <Container fluid className="sm:p-0">
          <div className="container-fluid sm:p-0">
            <AllVarisangyaPeriods />
          </div>
        </Container>
      </div>
    </>
  )
}

export default VarisangyaPeriods
