import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYAS_SUCCESS,
  GET_FAMILY_VARISANGYAS_FAIL,
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_VARISANGYA_CARDS_FAIL,
  GET_FAMILY_VARISANGYA_DETAIL,
  GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  CREATE_FAMILY_VARISANGYA,
  CREATE_FAMILY_VARISANGYA_SUCCESS,
  CREATE_FAMILY_VARISANGYA_FAIL,
  UPDATE_MAIN_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_FAIL,
  DELETE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA_SUCCESS,
  DELETE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  REFRESH_FAMILY_VARISANGYA,
  REFRESH_FAMILY_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
  GET_ALL_MAIN_VARISANGYAS,
  GET_ALL_MAIN_VARISANGYAS_SUCCESS,
  GET_ALL_MAIN_VARISANGYAS_FAIL,
  CREATE_MAIN_VARISANGYA,
  CREATE_MAIN_VARISANGYA_SUCCESS,
  CREATE_MAIN_VARISANGYA_FAIL,
  UPDATE_MAIN_VARISANGYA,
  UPDATE_MAIN_VARISANGYA_SUCCESS,
  UPDATE_MAIN_VARISANGYA_FAIL,
  DELETE_MAIN_VARISANGYA,
  DELETE_MAIN_VARISANGYA_SUCCESS,
  DELETE_MAIN_VARISANGYA_FAIL,
} from "./actionTypes"

export const getAllMainVarisangyas = (
  searchText,
  page,
  limit,
  type,
  ledgerId,
  mahallId,
  period,
  isAutopay
) => ({
  type: GET_ALL_MAIN_VARISANGYAS,
  payload: {
    searchText,
    page,
    limit,
    type,
    ledgerId,
    mahallId,
    period,
    isAutopay,
  },
})
export const getAllMainVarisangyasSuccess = mainVarisangyas => ({
  type: GET_ALL_MAIN_VARISANGYAS_SUCCESS,
  payload: mainVarisangyas,
})
export const getAllMainVarisangyasFail = error => ({
  type: GET_ALL_MAIN_VARISANGYAS_FAIL,
  payload: error,
})

export const getFamilyVarisangyaCards = () => ({
  type: GET_FAMILY_VARISANGYA_CARDS,
})
export const getFamilyVarisangyaCardsSuccess = familyVarisangyaCards => ({
  type: GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  payload: familyVarisangyaCards,
})
export const getFamilyVarisangyaCardsFail = error => ({
  type: GET_FAMILY_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getFamilyVarisangyaDetail = familyVarisangyaId => ({
  type: GET_FAMILY_VARISANGYA_DETAIL,
  familyVarisangyaId,
})

export const getFamilyVarisangyaDetailSuccess = familyVarisangyaDetail => ({
  type: GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  payload: familyVarisangyaDetail,
})

export const getFamilyVarisangyaDetailFail = error => ({
  type: GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  payload: error,
})

export const createMainVarisangya = (
  varisangyaData,
  history,
  handleClearLedgerField
) => ({
  type: CREATE_MAIN_VARISANGYA,
  payload: { varisangyaData, history, handleClearLedgerField },
})

export const createMainVarisangyaSuccess = mainVarisangyas => ({
  type: CREATE_MAIN_VARISANGYA_SUCCESS,
  payload: mainVarisangyas,
})

export const createMainVarisangyaFail = error => ({
  type: CREATE_MAIN_VARISANGYA_FAIL,
  payload: error,
})

export const updateMainVarisangya = (
  varisangyaData,
  varisangyaId,
  history
) => ({
  type: UPDATE_MAIN_VARISANGYA,
  payload: { varisangyaData, varisangyaId, history },
})

export const updateMainVarisangyaSuccess = mainVarisangyas => ({
  type: UPDATE_MAIN_VARISANGYA_SUCCESS,
  payload: mainVarisangyas,
})

export const updateMainVarisangyaFail = error => ({
  type: UPDATE_MAIN_VARISANGYA_FAIL,
  payload: error,
})

export const deleteMainVarisangya = (varisangyaId, history) => ({
  type: DELETE_MAIN_VARISANGYA,
  payload: { varisangyaId, history },
})

export const deleteMainVarisangyaSuccess = mainVarisangyas => ({
  type: DELETE_MAIN_VARISANGYA_SUCCESS,
  payload: mainVarisangyas,
})

export const deleteMainVarisangyaFail = error => ({
  type: DELETE_MAIN_VARISANGYA_FAIL,
  payload: error,
})

export const autoGenerateFamilyVarisangyas = () => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS,
})

export const autoGenerateFamilyVarisangyasSuccess = familyVarisangya => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  payload: familyVarisangya,
})

export const autoGenerateFamilyVarisangyasFail = error => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  payload: error,
})

export const refreshFamilyVarisangya = familyVarisangyaId => ({
  type: REFRESH_FAMILY_VARISANGYA,
  payload: { familyVarisangyaId },
})

export const refreshFamilyVarisangyaSuccess = familyVarisangya => ({
  type: REFRESH_FAMILY_VARISANGYA_SUCCESS,
  payload: familyVarisangya,
})

export const refreshFamilyVarisangyaFail = error => ({
  type: REFRESH_FAMILY_VARISANGYA_FAIL,
  payload: error,
})

export const updateFamilyVarisangyaAmountByFilter = (
  familyVarisangyaAmuontByFilter,
  history
) => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  payload: { familyVarisangyaAmuontByFilter, history },
})

export const updateFamilyVarisangyaAmountByFilterSuccess =
  familyVarisangyaAmuontByFilter => ({
    type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
    payload: familyVarisangyaAmuontByFilter,
  })

export const updateFamilyVarisangyaAmountByFilterFail = error => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  payload: error,
})
