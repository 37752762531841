import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import {
  deleteFamilyVarisangya,
  deleteVarisangyaPeriod,
  updateFamilyVarisangya,
  updateVarisangyaPeriod,
} from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UiModalUpdateVarisangyaPeriod = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const varisangya = data

  const { loading, language } = useSelector(state => ({
    loading: state.VarisangyaPeriod.loading,
    language: state.Layout.language,
  }))

  const [isActiveSwitch, setIsActiveSwitch] = useState()

  useEffect(() => {
    setIsActiveSwitch(varisangya?.is_active)
  }, [varisangya])

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateVarisangyaData = {
      ...values,
      is_active: isActiveSwitch,
      varisankya: params?.id,
    }
    dispatch(updateVarisangyaPeriod(updateVarisangyaData, varisangya?.id))
  }

  const handleDelete = id => {
    dispatch(deleteVarisangyaPeriod(id))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Varisangya</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="title"
                    type="text"
                    value={varisangya?.title}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="5">
                  <Label for="basicpill-email-input4">
                    {language === "mal" ? "സജീവമാണോ?" : "Is Active?"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isActiveSwitch ? true : false}
                      onChange={() => setIsActiveSwitch(!isActiveSwitch)}
                    />
                  </div>
                </Col>
                <Col sm="12" md="7">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="date"
                    type="date"
                    readOnly
                    value={varisangya?.date}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-between mt-5">
                <Button
                  type="button"
                  color="danger"
                  className="w-25 mx-2"
                  onClick={() => {
                    handleDelete(varisangya?.id)
                    onCloseclick()
                  }}
                >
                  {language === "mal" ? "ഇല്ലാതാക്കുക" : "Delete"}
                </Button>
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalUpdateVarisangyaPeriod
UiModalUpdateVarisangyaPeriod.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
}
