import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
// Authentication
import Login from "./auth/login/reducer"
//finished Prod Chart
import Dashboard from "./Dashboard/reducer"
//Education
import Educations from "./education/reducer"
//constituency
import Constituencies from "./constituency/reducer"
//Mahall
import Mahalls from "./mahall/reducer"
//Admins
import Admins from "./admin/reducer"
//committee
import Committees from "./committee/reducer"
//committeemember
import CommitteeMembers from "./committeeMember/reducer"
//committeemeeting
import CommitteeMeetings from "./committeeMeeting/reducer"
//committeemeeting
import CommitteeMeetingImages from "./committeeMeetingImage/reducer"
//committeemeetingattendance
import CommitteeMeetingAttendances from "./CommitteeMeetingAttendance/reducer"
//state
import States from "./state/reducer"
//district
import Districts from "./district/reducer"
//village
import Villages from "./village/reducer"
//family
import Families from "./family/reducer"
//familymember
import FamilyMembers from "./familyMember/reducer"
//job
import Jobs from "./job/reducer"
//institute
import Institutes from "./institute/reducer"
//instituteaccount
import InstituteAccounts from "./instituteAccount/reducer"
//instituteuser
import InstituteUsers from "./instituteuser/reducer"
//mahalluser
import MahallUsers from "./mahalluser/reducer"
//typeList
import TypeLists from "./typeList/reducer"
//userDetail
import Users from "./user/reducer"
//lsg
import Lsgs from "./lsg/reducer"
//Enquiries
import Enquiries from "./enquiry/reducer"
//Groups
import Groups from "./group/reducer"
//GroupAdmins
import GroupAdmins from "./groupadmin/reducer"
//surveyUsers
import SurveyUsers from "./surveyuser/reducer"
//Galleries
import Galleries from "./instituteGallery/reducer"
//Ledgers
import Ledgers from "./ledger/reducer"
//LedgerItems
import LedgerItems from "./ledgerItem/reducer"
//MasterAccountItems
import MasterAccountItems from "./masterAccount/reducer"
//LedgerItemCategories
import LedgerItemCategories from "./ledgerItemCategory/reducer"
//Nikah Registrations
import NikahRegistrations from "./nikah/reducer"
//Death Registrations
import DeathRegistrations from "./death/reducer"
//Zakats
import Zakats from "./zakat/reducer"
//ZakatItems
import ZakatItems from "./zakatItem/reducer"
//Varisangyas
import Varisangyas from "./varisangya/reducer"
//Unapproved Family
import UnapprovedFamilies from "./unapprovedFamily/reducer"
//Unapproved Family Member
import UnapprovedFamilyMembers from "./unapprovedFamilyMember/reducer"
//Banner
import Banners from "./banner/reducer"
//Notification
import Notifications from "./notification/reducer"
//Collection
import Collections from "./collection/reducer"
//Feed
import Feeds from "./feed/reducer"
//Country
import Countries from "./country/reducer"
//NocCertificate
import NocCertificates from "./nocCertificate/reducer"
//VarisangyaSplitLedgers
import VarisangyaSplitLedgers from "./varisangyaSplitLedger/reducer"
//FamilyVarisangyas
import FamilyVarisangyas from "./familyVarisangya/reducer"
//FamilyVarisangyaItems
import FamilyVarisangyaItems from "./familyVarisangyaItem/reducer"
//FamilyVarisangyaWallets
import FamilyVarisangyaWallets from "./familyVarisangyaWallet/reducer"
//FamilyMemberVarisangyas
import FamilyMemberVarisangyas from "./familyMemberVarisangya/reducer"
//FamilyMemberVarisangyaItems
import FamilyMemberVarisangyaItems from "./familyMemberVarisangyaItem/reducer"
//FamilyMemberVarisangyaWallets
import FamilyMemberVarisangyaWallets from "./familyMemberVarisangyaWallet/reducer"
//activitylog
import ActivityLogs from "./activityLog/reducer"
//assets
import Assets from "./assets/reducer"
//assetitems
import AssetItems from "./assetItems/reducer"
//assetitemTransactions
import AssetItemTransactions from "./assetItemTransactions/reducer"
//madrasa
import MadrasaLists from "./madrasa/reducer"
//class
import Classes from "./class/reducer"
//division
import Divisions from "./division/reducer"
//student
import Students from "./student/reducer"
//staff
import Staffs from "./staff/reducer"
//subject
import Subjects from "./subject/reducer"
//student attendance
import StudentAttendances from "./studentAttendance/reducer"
//timetable
import Timetable from "./timetable/reducer"
//studentVarisangya
import StudentVarisangyas from "./studentVarisangya/reducer"
//studentVarisangyaWallet
import StudentVarisangyaWallets from "./studentVarisangyaWallet/reducer"
//instituteWallet
import InstituteWallets from "./instituteWallet/reducer"
//instituteWalletContra
import InstituteWalletContra from "./instituteWalletContra/reducer"
// transations
import Transactions from "./Transactions/reducer"
// support tickets
import SupportTickets from "./SupportTIcket/reducer"

// super feeds
import SuperFeeds from "./Superfeeds/reducer"

// super banners
import SuperBanners from "./SuperBanner/reducer"

// main varisangyas
import MainVarisangyas from "./mainVarisangya/reducer"

// varisangya period
import VarisangyaPeriod from "./varisangyaPeriod/reducer"

// varisankya wallet
import VarisangyaWallets from "./varisangyaWallet/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Committees,
  CommitteeMeetings,
  CommitteeMeetingImages,
  CommitteeMeetingAttendances,
  States,
  Districts,
  Villages,
  Families,
  FamilyMembers,
  Jobs,
  Institutes,
  InstituteAccounts,
  CommitteeMembers,
  Educations,
  Admins,
  Mahalls,
  InstituteUsers,
  MahallUsers,
  Constituencies,
  Dashboard,
  TypeLists,
  Users,
  Lsgs,
  Enquiries,
  Groups,
  GroupAdmins,
  SurveyUsers,
  Galleries,
  Ledgers,
  LedgerItems,
  MasterAccountItems,
  LedgerItemCategories,
  NikahRegistrations,
  DeathRegistrations,
  Zakats,
  ZakatItems,
  Varisangyas,
  UnapprovedFamilies,
  UnapprovedFamilyMembers,
  Banners,
  Notifications,
  Collections,
  Feeds,
  Countries,
  NocCertificates,
  VarisangyaSplitLedgers,
  FamilyVarisangyas,
  FamilyVarisangyaItems,
  FamilyVarisangyaWallets,
  FamilyMemberVarisangyas,
  FamilyMemberVarisangyaItems,
  FamilyMemberVarisangyaWallets,
  ActivityLogs,
  Assets,
  AssetItems,
  AssetItemTransactions,
  MadrasaLists,
  Classes,
  Divisions,
  Students,
  Staffs,
  Subjects,
  StudentAttendances,
  Timetable,
  StudentVarisangyas,
  StudentVarisangyaWallets,
  InstituteWallets,
  InstituteWalletContra,
  Transactions,
  SupportTickets,
  SuperFeeds,
  SuperBanners,
  MainVarisangyas,
  VarisangyaPeriod,
  VarisangyaWallets,
})

export default rootReducer
