import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardTitle,
  CardBody,
  Spinner,
  Button,
  Badge,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import {
  // debounce,
  map,
  range,
} from "lodash"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//actions family
import UiModalAddFamilyMemberVarisangyaArrear from "../member/UiModalAddFamilyMemberVarisangyaArrear"

import {
  deleteFamily,
  getAllFamilyVarisangyaWallet,
  getFamilyDetail,
  getFamilyMemberDetail,
  getFamilyMemberVarisangyaWalletDetail,
  getVarisangyaWalletDetail,
} from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import MyPagination from "components/Common/MyPagination"
import UiModalAddFamilyVarisangyaArrear from "./UiModalAddFamilyVarisangyaArrear"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import MyPaginationNew from "components/Common/MyPagination2"

//Import Images

const FamilyWalletDetail = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  console.log(location)

  const [searchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalAddArrear, setModalAddArrear] = useState(false)

  const {
    familyDetails,
    // loading,
    allFamilyVarisangyaWallet,
    tableLoading,
    language,
    walletDetail,
    // familyVarisangyaItems
  } = useSelector(state => ({
    loading: state.VarisangyaWallets.tableLoading,
    detailLoading: state.Families.detailLoading,
    language: state.Layout.language,
    familyDetails: state.Families.familyDetail,
    allFamilyVarisangyaWallet:
      state.VarisangyaWallets.allFamilyVarisangyaWallet,
    tableLoading: state.FamilyMemberVarisangyaWallets.tableLoading,
    walletDetail: state.VarisangyaWallets.varisangyaWalletDetail,
    // familyVarisangyaItems: state.FamilyVarisangyas.familyVarisangyaItems'
  }))

  const totalPages = Math.ceil(allFamilyVarisangyaWallet?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  const handleDeleteEvent = () => {
    dispatch(deleteFamily(familyMembwalletDetailserDetail?.id, history))
    setIsOpen(false)
    // history.push("/families")
  }

  console.log(location?.state?.walletId)

  // useEffect(() => {
  //   dispatch(getFamilyDetail(params.walletId))
  // }, [dispatch, params.id, params?.type])

  useEffect(() => {
    dispatch(getVarisangyaWalletDetail(location?.state?.walletId, params?.type))
  }, [params?.walletId, params?.type])

  useEffect(() => {
    dispatch(
      getAllFamilyVarisangyaWallet(
        pageSend(),
        searchText,
        limit,
        location?.state?.walletId,
        ""
        // walletDetail?.family
      )
    )
  }, [dispatch, pageSend(), limit, searchText])

  // useEffect(() => {
  //   // dispatch(getFamilyVarisangyaWalletDetail(params.id, page, searchText))
  //   dispatch(
  //     getFamilyMemberVarisangyaWalletDetail(params.id, page, searchText, "")
  //   )
  // }, [page, searchText, params.id])

  // const debounceMahallFamilyFamilyMemberSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "balance",
      text: language === "mal" ? "ബാലൻസ്" : "Balance",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "വിവരണം" : "Remarks",
    },
    // {
    //   dataField: "action",
    //   text: language === 'mal' ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    // }
  ]

  const familyMemberVarisangyaWalletsData = map(
    allFamilyVarisangyaWallet?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      amount: (
        <p
          className={
            item?.trn_type === "credit" ? "text-success" : "text-danger"
          }
        >
          {item?.amount}
        </p>
      ),
      balance: <p className="text-primary">{item?.balance}</p>,
      date: item?.date_added && moment(item?.date_added).format("DD/MM/yyyy"),
      // action: (
      //   <div className="m-0">
      //     <Link to={`/varisangya/update/${item?.id}`} className="m-0">
      //       <Button
      //         type="button"
      //         color="primary"
      //         className=" btn w-xs btn-sm"
      //       >
      //         {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
      //         {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
      //       </Button>
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // function getAge(dateString) {
  //   var today = new Date()
  //   var birthDate = new Date(dateString)
  //   if (dateString) {
  //     const startDate = moment(today)
  //     const timeEnd = moment(birthDate)
  //     const diff = startDate.diff(timeEnd)
  //     var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
  //     // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
  //     // console.log("age: ", age)
  //     if (age < 0) age = 0
  //     return age
  //   } else {
  //     return 0
  //   }
  // }

  console.log(familyDetails)

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalAddFamilyVarisangyaArrear
        show={modalAddArrear}
        onCloseclick={() => setModalAddArrear(false)}
        data={walletDetail}
        page="wallet"
      />

      <Row>
        <Col md={12} lg={8}>
          {/* <div className="col-md-6 p-3 mb-3" style={{ backgroundColor: "#c3fade", borderRadius: "8px" }}>
                  <h4 className="text-khaf-blue">Wallet</h4>
                  <p style={{ fontSize: "16px", color: "black" }}>Family : {familyDetail?.house_name}</p>
                  <p style={{ fontSize: "16px", color: "black" }}>Mahall ID : {familyDetail?.mahall_custom_id}</p>
                  <p style={{ fontSize: "16px", marginBottom: "0px", color: "black" }}>Available Balance : {familyDetail?.varisankya_wallet}</p>
                </div> */}
          {walletDetail && (
            <Row>
              <Col md="12">
                <Card style={{ backgroundColor: "#e6fcee" }}>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="d-flex">
                          <h5 className="font-size-22 font-weight-bold mt-1">
                            {walletDetail?.family_name}
                          </h5>
                        </div>
                        {walletDetail?.mahall_custom_id && (
                          <Badge
                            className="bg-primary mx-2 bg-success"
                            style={{ fontSize: "12px" }}
                          >
                            {language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"} :{" "}
                            {walletDetail?.mahall_custom_id}
                          </Badge>
                        )}
                      </Col>

                      <Col md="6" className="">
                        {walletDetail?.house_number &&
                        walletDetail?.ward_number ? (
                          <div className="text-muted mt-1 d-flex">
                            <h5 className="font-size-12 text-muted font-weight-bold mt-1">
                              {walletDetail?.house_number}/
                              {walletDetail?.ward_number}
                            </h5>
                          </div>
                        ) : (
                          ""
                        )}
                        {walletDetail?.contact_number && (
                          <p className="mb-0">
                            <i className="bx bx-phone text-primary me-1" />{" "}
                            {walletDetail?.contact_number || ""}
                          </p>
                        )}
                        {/* <p className="text-">
                                          {language === 'mal' ? 'വാലറ്റ് ബാലൻസ് : ' : 'Wallet Balance :'} 
                                          <span className="fw-bold ">
                                            {familyDetails?.varisankya_wallet}
                                          </span>
                                        </p> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Card>
            <Col className="pt-3 px-3">
              <Row className="d-flex align-items-center">
                <Col className="d-flex align-items-center">
                  <p className="font-size-18">
                    {language === "mal"
                      ? "വാലറ്റ് ബാലൻസ് : "
                      : "Wallet Balance : "}
                    <span
                      className={`fw-bold + ${
                        walletDetail?.balance == 0
                          ? "text-primary"
                          : walletDetail?.balance > 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {walletDetail?.balance}
                    </span>
                  </p>
                </Col>
                <Col>
                  <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{
                      width: language === "mal" ? "170px" : "150px",
                    }}
                    className="btn btn-danger m-auto me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === "mal" ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Arrears"}
                  </Button>
                </Col>
              </Row>
            </Col>
            <hr />
            <CardTitle className="mx-3 mt-3">
              {language === "mal" ? "ഇടപാടുകൾ :" : "Transactions :"}
            </CardTitle>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={familyMemberVarisangyaWalletsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {/* <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e => debouncefamilyVarisangyaWalletSearch(e.target.value)}
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                    {tableLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {(!allFamilyVarisangyaWallet?.results ||
                          allFamilyVarisangyaWallet?.results?.length <= 0) && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info"
                          >
                            {language === "mal"
                              ? "ഇടപാടുകൾ ഒന്നും ഇല്ല"
                              : "No Transactions Yet"}
                          </p>
                        )}
                        <MyPaginationNew
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {/* {familyVarisangyaWallets && familyVarisangyaWallets?.results?.length <= 0 && (
                      <p style={{ fontSize: "15px" }} className="text-center text-info">{language === 'mal' ? "വരിസംഖ്യകൾ ഒന്നും ഇല്ല" : "No Varisangyas Yet"}</p>
                    )} */}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FamilyWalletDetail

FamilyWalletDetail.propTypes = {
  history: PropTypes.object,
}
