import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYA_CARDS,
  UPDATE_MAIN_FAMILY_VARISANGYA,
  CREATE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA,
  GET_FAMILY_VARISANGYA_DETAIL,
  GET_FAMILY_VARISANGYAS_SUCCESS,
  GET_FAMILY_VARISANGYAS_FAIL,
  GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_VARISANGYA_CARDS_FAIL,
  CREATE_FAMILY_VARISANGYA_SUCCESS,
  CREATE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  UPDATE_FAMILY_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_FAIL,
  DELETE_FAMILY_VARISANGYA_SUCCESS,
  DELETE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  REFRESH_FAMILY_VARISANGYA,
  REFRESH_FAMILY_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
  GET_ALL_MAIN_VARISANGYAS_SUCCESS,
  GET_ALL_MAIN_VARISANGYAS,
  GET_ALL_MAIN_VARISANGYAS_FAIL,
  CREATE_MAIN_VARISANGYA,
  CREATE_MAIN_VARISANGYA_SUCCESS,
  CREATE_MAIN_VARISANGYA_FAIL,
  UPDATE_MAIN_VARISANGYA,
  UPDATE_MAIN_VARISANGYA_SUCCESS,
  UPDATE_MAIN_VARISANGYA_FAIL,
  DELETE_MAIN_VARISANGYA,
  DELETE_MAIN_VARISANGYA_SUCCESS,
  DELETE_MAIN_VARISANGYA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  mainVarisangyas: [],
  familyVarisangyaCards: {},
  familyVarisangyaDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  // autoGenerateLoading: false,
  refreshLoading: false,
  createdVarisangya: {},
}

const MainVarisangyas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MAIN_VARISANGYAS:
    case GET_FAMILY_VARISANGYA_CARDS:
    case UPDATE_MAIN_VARISANGYA:
    case CREATE_MAIN_VARISANGYA:
    case DELETE_MAIN_VARISANGYA:
    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER:
      return {
        ...state,
        loading: true,
      }
    case GET_FAMILY_VARISANGYA_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case AUTO_GENERATE_FAMILY_VARISANGYAS:
      return {
        ...state,
        loading: true,
      }
    case REFRESH_FAMILY_VARISANGYA:
      return {
        ...state,
        refreshLoading: true,
      }

    case GET_ALL_MAIN_VARISANGYAS_SUCCESS:
      return {
        ...state,
        mainVarisangyas: action.payload,
        loading: false,
      }
    case GET_ALL_MAIN_VARISANGYAS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_VARISANGYA_CARDS_SUCCESS:
      return {
        ...state,
        familyVarisangyaCards: action.payload,
        loading: false,
      }
    case GET_FAMILY_VARISANGYA_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_MAIN_VARISANGYA_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        mainVarisangyas: {
          ...state.mainVarisangyas,
          results: [action.payload, ...state.mainVarisangyas.results],
        },
        createdVarisangya: action.payload,
        loading: false,
      }

    case CREATE_MAIN_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_VARISANGYA_DETAIL_SUCCESS:
      return {
        ...state,
        familyVarisangyaDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_FAMILY_VARISANGYA_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_MAIN_VARISANGYA_SUCCESS:
      return {
        ...state,
        // familyVarisangyaDetail: action.payload,
        mainVarisangyas: {
          results: state.mainVarisangyas.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_MAIN_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_MAIN_VARISANGYA_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        mainVarisangyas: {
          results: state?.mainVarisangyas?.results.filter(
            familyVarisangya => familyVarisangya.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_MAIN_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS:
      return {
        ...state,
        // familyVarisangyas: action.payload,
        loading: false,
      }
    case AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL:
      return {
        ...state,
        // error: action.payload,
        loading: false,
      }

    case REFRESH_FAMILY_VARISANGYA_SUCCESS:
      return {
        ...state,
        familyVarisangyaItems: action.payload,
        refreshLoading: false,
      }
    case REFRESH_FAMILY_VARISANGYA_FAIL:
      return {
        ...state,
        // error: action.payload,
        refreshLoading: false,
      }

    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default MainVarisangyas
