import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Row, Modal, Button, Label } from "reactstrap"

import {
  createFamilyVarisangyaWallet,
  createFamilyVarisangyaWalletNew,
  getInstituteWalletDropdown,
} from "store/actions"
import Select from "react-select"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const UiModalAddFamilyVarisangyaArrear = ({
  show,
  onCloseclick,
  data,
  varisankyaId,
  page,
}) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const location = useLocation()
  const varisangya = data

  const { loading, language, searchWallets } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    searchWallets: state.InstituteWallets.searchWallets,
  }))

  const [wallet, setWallet] = useState("Select wallet...")
  const [walletId, setWalletId] = useState("")

  const [isActiveSwitch, setIsActiveSwitch] = useState()
  console.log(isActiveSwitch)
  useEffect(() => {
    setIsActiveSwitch(varisangya?.is_active)
  }, [varisangya])

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createFamilyVarisangyaTransactionData = {
      family: data?.family || data?.id,
      amount: values.amount ? parseInt(values.amount) : 0,
      trn_type: "debit",
      remark: values.remark,
      varisankya_family_wallet: location?.state?.walletId,
      varisankya: varisankyaId,
      institute_wallet: walletId,
    }
    // console.log(createVarisangyaData);
    dispatch(
      createFamilyVarisangyaWalletNew(
        createFamilyVarisangyaTransactionData,
        history,
        page
      )
    )
  }

  const [searchWallet, setSearchWallet] = useState("")

  useEffect(() => {
    dispatch(getInstituteWalletDropdown("", searchWallet))
  }, [dispatch, searchWallet])

  const handleEntersWallet = textEntered => {
    setSearchWallet(textEntered)
  }

  const optionGroupWallet = [
    {
      options: searchWallets?.map((results, index) => ({
        key: index,
        label: results.institute_wallet_name,
        value: results.id,
      })),
    },
  ]

  function handlerFinalValueWallet(event) {
    // dispatch(getLedgerItemCategoryDropdown(event.value))
    setWalletId(event.value)
    setWallet(event.label)
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "വരിസംഗ്യ കുടിശ്ശിക ചേർക്കൽ"
                : "Add Varisangya Arrear"}
              <hr />
              <p style={{ fontSize: "14px" }} className="fw-normal">
                {language === "mal"
                  ? "താഴെയുള്ള സമർപ്പിക്കുക ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ, തിരഞ്ഞെടുത്ത കുടുംബത്തിന് നിങ്ങൾ വരിസംഗ്യ കുടിശ്ശിക ചേർക്കപ്പെടുന്നതാണ്."
                  : "By clicking the below Submit Button, you will add varisangya arrear/due amount for the selected family."}
              </p>
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm={12} className="mb-3">
                  <AvField
                    id="horizontal-firstname-Input"
                    name="familyName"
                    type="text"
                    value={data?.family_name || data?.house_name}
                    readOnly
                    label={language === "mal" ? "കുടുംബം" : "Family"}
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <Label>
                  {language === "mal"
                    ? "ഇൻസ്റ്റിറ്റ്യൂട്ട് വാലറ്റ്"
                    : "Institute Wallet"}
                  <span className="text-danger">&#42;</span>
                </Label>
                <Select
                  onInputChange={handleEntersWallet}
                  placeholder={wallet}
                  options={optionGroupWallet}
                  classNamePrefix="select2-selection"
                  onChange={handlerFinalValueWallet}
                  type="text"
                  title="Institute Wallet"
                  required="required"
                />
              </div>
              <Row className="">
                <Col sm={4}>
                  <AvField
                    id="horizontal-firstname-Input"
                    name="amount"
                    type="number"
                    label={language === "mal" ? "തുക" : "Amount"}
                    validate={{
                      required: { value: true },
                      min: {
                        value: 1,
                        errorMessage: `${
                          language === "mal"
                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                            : "Amount must be greater than 0"
                        }`,
                      },
                    }}
                  />
                </Col>
                <Col sm={8}>
                  <AvField
                    id="horizontal-firstname-Input"
                    name="remark"
                    value="Arrear"
                    type="text"
                    label={language === "mal" ? "വിവരണം" : "Remarks"}
                  />
                </Col>
              </Row>
              <div className="row w-100 justify-content-end mt-3">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalAddFamilyVarisangyaArrear

UiModalAddFamilyVarisangyaArrear.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
  varisankyaId: propTypes.any,
  page: propTypes.any,
}
